import React from "react";
import {
  Grid,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  withStyles,
} from "@material-ui/core";
import File from "@core/components/File";
import styles from "./styles";

const SupplierCertificates = ({classes, test}) => {
  return (
    <>
      <Grid container spacing={40}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align={"center"}>Creator</TableCell>
                <TableCell align={"center"}>Type</TableCell>
                <TableCell align={"center"}>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {test.properties.elements.map((element) => {
                const [dir, fileName] = element.file.preview_path.substring(1).split('/');
                return (
                    <TableRow>
                      <TableCell align={"center"}>{element.creator}</TableCell>
                      <TableCell align={"center"}>{element.certificateType}</TableCell>
                      <TableCell align={"center"}>
                        {element.file ? (
                            <File
                                fileName={fileName}
                                dir={dir}
                            />
                        ) : "No file uploaded"}
                      </TableCell>
                    </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(SupplierCertificates);
