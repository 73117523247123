import React from "react";
import {Grid} from "@material-ui/core";
import {observer} from 'mobx-react-lite';
import {useStores} from "@core/hooks/useStores";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Statistics = () => {
    const {StatisticsStore} = useStores();
    const classes = useStyles();
    return (
        <>
            <img src="/images/oil-well.png" alt="oil-well"/>
            {/*<Grid container spacing={3} className={classes.statistics}>*/}
            {/*    <Grid item xs>*/}
            {/*        <p>{StatisticsStore.statistics.companies}</p>*/}
            {/*        <p>Companies created</p>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs>*/}
            {/*        <p>{StatisticsStore.statistics.certificates}</p>*/}
            {/*        <p>Total certificates owned</p>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs>*/}
            {/*        <p>{StatisticsStore.statistics.tests}</p>*/}
            {/*        <p>Tests performed</p>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs>*/}
            {/*        <p>{StatisticsStore.statistics.transactions}</p>*/}
            {/*        <p>Digital signatures</p>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </>
    );
}

export default observer(Statistics);