export default () => ({
    searchField: {
        "& div": {
            backgroundColor: "#ffffff",
        }
    },
    input: {
        paddingTop: "15.5px",
        paddingBottom: "15.5px",
    },
    inputContainer: {
        flexGrow: 2,
    }
});