const STATUSES = {
    AWAITING: "AWAITING",
    PENDING:  "PENDING",
    ACCEPTED: "ACCEPTED",
    DECLINED: "DECLINED",
};

const STATUS_NAME_BY_STATUS = {
    [STATUSES.AWAITING]: "Awaiting Supplier",
    [STATUSES.PENDING]: "Pending Review",
    [STATUSES.ACCEPTED]: "Accepted",
    [STATUSES.DECLINED]: "Declined"
};

const TABS = {
    OPEN: "open",
    CLOSED: "closed"
};


export {STATUS_NAME_BY_STATUS, STATUSES, TABS}