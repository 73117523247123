import React, {useState} from "react";
import {Button, Dialog, Typography, Grid, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, FieldArray} from 'formik';
import {isEmpty, keys, map} from 'ramda';
import * as yup from 'yup';
import Input from "@core/components/TextField";
import DropZone from "../DropZone";
import styles from "./styles";

const useStyles = makeStyles(styles);

const getInitialValues = (productProperties) => ({
    heat: "",
    material_grade: "",
    tp_UNS: "",
    properties: {
        norm: "",
        lotId: "",
    },
    items: [{
        quantity: "",
        ...productProperties,
    }],
});

const validationSchema = yup.object().shape({
    heat: yup.string().required("This field is required!"),
    material_grade: yup.string().when("tp_UNS", {
        is: (tpUNS) => !tpUNS,
        then: yup.string(),
        otherwise: yup.string()
    }),
    tp_UNS: yup.string().when("material_grade", {
        is: (grade) => !grade,
        then: yup.string(),
        otherwise: yup.string()
    }),
    properties: yup.object().shape({
        norm: yup.string().required("This field is required!"),
        lotId: yup.string(),
    }),
    items: yup.array().of(yup.lazy((item) =>
        yup.object(
            map(() => yup.string().required("This field is required"), item)
        ))).required(),
}, [["tp_UNS", "material_grade"]]);

const CertificateForm = ({properties, lineItem, onSubmit, orderNumber, certificate, disabled, lineItemUploads}) => {
    const [open, setOpen] = useState(false);
    const [unit, setUnit] = useState('material_grade');
    const classes = useStyles();

    const productProperties = properties.reduce((acc, {name}) => {
        return {...acc, [name]: ""}
    }, {});

    const initialValues = certificate._id ? certificate : getInitialValues(productProperties);

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="lg"
                PaperProps={{
                    classes: {
                        root: classes.paper,
                    },
                }}
                classes={{
                    paperWidthLg: classes.paperWidth,
                }}
            >
                <Typography component="h6" variant="h6" gutterBottom>
                    {`Line item ${lineItem + 1}: Upload files`}
                </Typography>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        await onSubmit(values);
                        setOpen(false);
                    }}
                    validationSchema={validationSchema}
                >
                    {(props) => {
                        const {handleSubmit, values, setFieldValue, isValid} = props;
                        return (
                            <Grid container spacing={2}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={2}>
                                        <Input
                                            disabled={disabled}
                                            required
                                            name="heat"
                                            label="Heat"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            disabled={disabled}
                                            name="properties.lotId"
                                            label="Lot ID"
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={2}>
                                        <Input
                                            disabled={disabled}
                                            required
                                            name="properties.norm"
                                            label="Norm"
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            disabled={disabled}
                                            name={unit}
                                            label={unit === "material_grade" ? "Grade" : "TP UNS"}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={3} container alignItems="center">
                                        <RadioGroup
                                            className={classes.unitSelect}
                                            value={unit}
                                            onChange={({target}) => {
                                                setFieldValue(target.value, values[unit]);
                                                setFieldValue(unit, "");
                                                setUnit(target.value);
                                            }}
                                        >
                                            <FormControlLabel
                                                value="material_grade"
                                                control={<Radio disabled={disabled} color="primary"/>}
                                                label="Grade"
                                            />
                                            <FormControlLabel
                                                value="tp_UNS"
                                                control={<Radio disabled={disabled} color="primary"/>}
                                                label="TP UNS"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography component="h6" variant="h6">
                                        Items
                                    </Typography>
                                </Grid>
                                <FieldArray name="items">
                                    {({remove}) => values.items.map((item, index) => (
                                        <Grid item container spacing={2} key={index}>
                                            <Grid item xs={2}>
                                                <Input
                                                    disabled={disabled}
                                                    required
                                                    name={`items.${index}.quantity`}
                                                    label="Quantity"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{inputProps: {min: 0}}}
                                                />
                                            </Grid>
                                            {properties.map(property => (
                                                <Grid item key={property} xs={2}>
                                                    <Input
                                                        disabled={disabled}
                                                        required
                                                        name={`items.${index}.${property.name}`}
                                                        label={property.label}
                                                        type="text"
                                                        variant="outlined"
                                                        InputProps={{endAdornment: property.measurements}}
                                                    />
                                                </Grid>
                                            ))}
                                            {values.items.length > 1 && (
                                                <Grid item xs={1} container alignItems="center">
                                                    <Button
                                                        disabled={disabled}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}
                                </FieldArray>
                                <Grid item>
                                    <Button
                                        disabled={disabled}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const newItem = {quantity: "", ...productProperties};
                                            const items = values.items.concat(newItem);
                                            setFieldValue("items", items);
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <DropZone
                                        disabled={disabled}
                                        lineItem={lineItem}
                                        orderNumber={orderNumber}
                                    />
                                </Grid>
                                {!disabled && (
                                    <Grid item xs={12} container justify="flex-end">
                                        <Button
                                            disabled={!isValid || isEmpty(lineItemUploads)}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        )
                    }}
                </Formik>
            </Dialog>
            <Button
                fullWidth
                color="primary"
                onClick={() => setOpen(true)}
            >
                {certificate._id ? "Edit" : "Upload"}
            </Button>
        </>
    );
}

export default CertificateForm;
