export default (theme) => ({
    container: {
        height: "100vh",
        backgroundColor: "#f5f5f5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    content: {
        width: 1400,
        height: 939,
    },
    loginSection: {
        backgroundColor: "#ffffff",
        padding: "0 80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: 308,
            height: 80,
            marginBottom: 30,
        }
    },
    statisticSection: {
        padding: "0 46px",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& img": {
            width: 440,
            height: 550,
        },
    },
});