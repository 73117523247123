export default (theme) => ({
    item: {
        color: '#616161',
        width: 100,
        height: 110,
        zIndex: 5,
        maxWidth: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
        boxSizing: 'border-box',
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        borderRadius: 4,
        position: "relative",
    },
    verifiedImage: {
        position: "absolute",
        top: 105,
        right: 110,
        height: 40,
        width: 40,
        color: theme.palette.success.main,
    },
    description: {
        fontSize: 16,
        lineHeight: "1.3",
    },
    tooltip: {
        backgroundColor: "#ffffff",
        border: "1px solid #c4c4c4",
        color: "#616161",
    },
    tooltipPlacementRight: {
        margin: "0 -100px",
    },
});