export default (theme) => ({
    submitButton: {
        marginTop: 30,
    },
    signUpInfo: {
        color: "#616161",
        padding: 20,
        fontSize: 18,
        "& a": {
            color: theme.palette.primary.main,
        },
    },
});