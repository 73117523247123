export default (theme) => ({
  container: {
    width: "100%"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  infoRow: {
    marginBottom: 15,
    "& h4": {
      fontWeight: 400,
      color: "#616161",
      marginBottom: 5,
    }
  },
  infoCell: {
    fontWeight: 700,
    color: "#616161",
  },
  table: {
    "& td": {
      textAlign: "center",
      width: "10%"
    }
  },
  bold: {
    fontWeight: 600,
    color: "#000",
    fontSize: 16,
  },
  formula: {
    fontWeight: 600,
    color: "#2196f3",
    fontSize: 16,
  },
  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },
  tooltip: {
    backgroundColor: "#616161",
    color: "#fff",
    maxWidth: "100%",
    fontSize: 20,
    border: "1px solid #dadde9",
  },
  greenTitle: {
    color: "#4caf50"
  },

  redTitle: {
    color: "#f44336"
  },
});

