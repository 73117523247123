import React from "react";
import DefaultFerrite from "./defaultFerrite";
import NewFerrite from "./newFerrite";

const FerriteController = (props) => {
  const viewConfig = {
    default: <DefaultFerrite {...props}/>,
    newFerrite: <NewFerrite {...props}/>
  };
  const type = props.test.properties.type || "default";

  return viewConfig[type];
};

export default FerriteController;
