import { makeObservable, observable, action } from "mobx"
import axios from "axios";
import {platform} from "config";

class StatisticsStore {
    statistics = {}

    constructor() {
        makeObservable(this, {
            statistics: observable,
            getStatistics: action
        })
    }

    async getStatistics() {
        const response = await axios({
            method: "get",
            url: `${platform}/statistics`,
        });
        this.statistics = response;
    }
}
export default new StatisticsStore();