export default () => ({
  table: {
    "& th": {
      fontWeight: "bold",
      fontSize: 18
    },
    "& td": {
      fontSize: 16,
      border: "1px solid rgba(224, 224, 224, 1)",
    }
  },
  img: {
    maxWidth: "100%",
    maxHeight: 500
  }
});