import React from "react";
import {
  withStyles,
  Grid,
  List,
  ListItem
} from "@material-ui/core";
import styles from "./styles";

const Statement = (props) => {
  const {classes, test} = props;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List>
                {test.properties.elements.map((el, index) =>
                  <ListItem key={index} className={classes.li}>
                    {el.statement}
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(Statement);
