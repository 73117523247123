export default () => ({
    paper: {
        padding: 24,
    },
    unitSelect: {
        flexDirection: "row",
    },
    paperWidth: {
        maxWidth: 1100,
    }
});