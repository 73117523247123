import { makeObservable, observable, action } from "mobx"
import axios from "axios";
import {indexBy, prop} from "ramda";
import {api} from "config";

class TestsStore {
    isLoaded = false;
    testsById = {}

    constructor() {
        makeObservable(this, {
            isLoaded: observable,
            testsById: observable,
            getList: action,
            getTest: action,
        })
    }

    async getList() {
        const response = await axios({
            method: "get",
            url: `${api}/tests/list`,
        });
        this.testsById = indexBy(prop('_id'), response);
        this.isLoaded = true;
    }

    async getTest(testId) {
        const response = await axios({
            method: "get",
            url: `${api}/tests/single`,
            params: {testId}
        });
        const [test] = response;
        this.testsById[test._id] = test;
    }
}
export default new TestsStore();