import { makeObservable, observable, action } from "mobx"
import axios from "axios";
import {indexBy, prop} from "ramda";
import {api} from "config";

class ProductsStore {
    isLoading = false;
    productsByName = {}

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            productsByName: observable,
            getList: action,
        })
    }

    async getList() {
        this.isLoading = true;
        const response = await axios({
            method: "get",
            url: `${api}/products`,
        });
        this.productsByName = indexBy(prop('name'), response);
        this.isLoading = false;
    }
}
export default new ProductsStore();