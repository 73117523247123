import React, {Component} from "react";
import {withStyles, Table, TableBody, TableCell, TableRow, Paper} from "@material-ui/core";
import styles from "./styles";

class HardnessTable extends Component {

  render() {

    const {table, classes} = this.props;
    const countColumns = 8;
    const tables = Array(Math.ceil(table.length / countColumns)).fill();

    return (
      <div>
        {tables.map((nil, tableIndex) => {
          const from = tableIndex * countColumns;
          const to = tableIndex * countColumns + countColumns;

          const tableData = table.slice(from, to);

          return <Table style={{width: `${((tableData.length + 1 )/ (countColumns + 1)) * 100  }%`}} key={tableIndex}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.bold}>Pos</TableCell>
                {tableData.map((el, index) => <TableCell key={`${tableIndex}.position.${index}`}>{el.position}</TableCell>)}
              </TableRow>
              <TableRow>
                <TableCell className={classes.bold}>Min</TableCell>
                {tableData.map((el, index) => <TableCell key={`${tableIndex}.min.${index}`}>{el.min || 0}</TableCell>)}
              </TableRow>
              <TableRow>
                <TableCell className={classes.bold}>Value</TableCell>
                {tableData.map((el, index) => <TableCell key={`${tableIndex}.value.${index}`} className={classes.green}>{el.value}</TableCell>)}
              </TableRow>
              <TableRow>
                <TableCell className={classes.bold}>Max</TableCell>
                {tableData.map((el, index) => <TableCell key={`${tableIndex}.max.${index}`}>{el.max}</TableCell>)}
              </TableRow>
            </TableBody>
          </Table>;
        })}
      </div>
    );
  }
}

export default withStyles(styles)(HardnessTable);
