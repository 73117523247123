export default (theme) => ({
  message: {
    marginBottom: 10,
  },
  feedback: {
    marginBottom: 10,
  },
  accordionSummaryContent: {
    display: "block",
  },
  container: {
    marginTop: 20,
  },
});