import React, {Component} from "react";
import {Table, TableHead, TableBody, TableCell, TableRow, Paper, withStyles} from "@material-ui/core";
import styles from "./styles";

class ImpactTestTable extends Component {

  getAverageEnergy = (element) => {
    const t1 = element.energyJoule1 || element.test1;
    const t2 = element.energyJoule2 || element.test2;
    const t3 = element.energyJoule3 || element.test3;

    return ((parseFloat(t1) + parseFloat(t2) + parseFloat(t3))/3).toFixed(3);
  };

  getAverageExpansion = (element) => {
    const t1 = element.lateralExpansion1;
    const t2 = element.lateralExpansion2;
    const t3 = element.lateralExpansion3;

    if(!t1 || !t2 || !t3) {
      return "-";
    }

    return ((parseFloat(t1) + parseFloat(t2) + parseFloat(t3))/3).toFixed(3);
  };

    getTimesMin = (elements, average) => {
      return elements.filter((E) => E < average).length;
    };

    testColor = (testValue, compareValue ) => compareValue > testValue ? "unsatisfactory": "";

    averageTemp = (t1,t2,t3) => {
      return ((parseFloat(t1) + parseFloat(t2) + parseFloat(t3))/3).toFixed(3);
    };

    get isLateralExpansion() {
      return this.props.table.elements.reduce((acc, curr) => {
        const LEexist = !!curr.lateralExpansion1 || !!curr.lateralExpansion2 || !!curr.lateralExpansion3;

        if(LEexist) acc = true;

        return acc;
      }, false);
    }

    render() {
      const {table, classes} = this.props;

      return (
        <div>
          <Table className={classes.impactTable}>
            <TableHead>
              <TableRow>
                <TableCell>Lab</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>{"\u00b0"}C</TableCell>
                <TableCell>
                  Impact energy Joule
                  <div className={classes.cellContainer}>
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                  </div>
                </TableCell>
                <TableCell>EJ Average</TableCell>
                <TableCell>{"Times < Min"}</TableCell>
                {this.isLateralExpansion && (
                  <>
                    <TableCell>
                      Lateral expansion
                      <div className={classes.cellContainer}>
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                      </div>
                    </TableCell>
                    <TableCell>LA Average</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.elements.map((element, index) => {
                const LEexist = !!element.lateralExpansion1 || !!element.lateralExpansion2 || !!element.lateralExpansion3;

                return (
                  <TableRow key={index}>
                    <TableCell>{element.laboratory || "-"}</TableCell>
                    <TableCell>{element.location}</TableCell>
                    <TableCell align="center">{table.temperature}</TableCell>
                    <TableCell align="center">
                      <div className={classes.cellContainer}>
                        <span className={this.testColor(element.energyJoule1, table.single)}>
                          {element.energyJoule1 || element.test1}
                        </span>
                        <span className={this.testColor(element.energyJoule2, table.single)}>
                          {element.energyJoule2 || element.test2}
                        </span>
                        <span className={this.testColor(element.energyJoule3, table.single)}>
                          {element.energyJoule3 || element.test3}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {this.getAverageEnergy(element)}
                    </TableCell>
                    <TableCell align="center" className={this.getTimesMin([element.energyJoule1, element.energyJoule2, element.energyJoule3],table.single) > 1 ? "unsatisfactory" : "satisfactory"}>
                      {this.getTimesMin([element.energyJoule1, element.energyJoule2, element.energyJoule3],table.single)}
                    </TableCell>
                    {LEexist && (
                      <>
                        <TableCell align="center">
                          <div className={classes.cellContainer}>
                            <span>
                              {element.lateralExpansion1 || "-"}
                            </span>
                            <span>
                              {element.lateralExpansion2 || "-"}
                            </span>
                            <span>
                              {element.lateralExpansion3 || "-"}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {this.getAverageExpansion(element)}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );})}
              <TableRow>
                <TableCell> - </TableCell>
                <TableCell>
                  Norm
                </TableCell>
                <TableCell align="center">
                  {table.temperature}
                </TableCell>
                <TableCell align="center">
                  {"\u2265"}{table.single}
                </TableCell>
                <TableCell align="center">
                  {"\u2265"}{table.average}
                </TableCell>
                <TableCell align="center">
                  {"\u2264"}1
                </TableCell>
                {this.isLateralExpansion && (
                  <>
                    <TableCell align="center">
                      -
                    </TableCell>
                    <TableCell align="center">
                      -
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    }
}

export default withStyles(styles)(ImpactTestTable);
