import React from 'react';
import {observer} from 'mobx-react-lite';
import {values} from "ramda";
import CertificatePdf from "@core/components/CertificatePdf";
import {downloadJSON, downloadPdf, downloadXml} from "@core/helpers";
import DashboardHeader from "../DashboardHeader";
import {useStores} from "@core/hooks/useStores";

const Header = () => {
    const {CertificatesStore} = useStores();
    return (
        <DashboardHeader
            items={values(CertificatesStore.certificatesById)}
            downloadPdf={async (selectedItems) => {
                const List = selectedItems.map(item => <CertificatePdf certificate={item}/>);
                await downloadPdf('certificates.pdf', List);
            }}
            downloadXml={(selectedItems) => {
                downloadXml('certificates.xml', {certificates: selectedItems});
            }}
            downloadJSON={(selectedItems) => {
                downloadJSON('certificates.json', selectedItems);
            }}
            name="New certificates"
        />
    );
}

export default observer(Header);