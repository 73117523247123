import React from 'react';
import CertificatePdf from "@core/components/CertificatePdf";
import {getCertificateExportId} from "@core/api/exportService";
import {downloadJSON, downloadPdf, downloadXml} from "@core/helpers";
import ActionsCell from "../ActionsCell";

const CertificateActions = ({certificate}) => {

    const getPdf = async (exportId) => {
        const Certificate = (
            <CertificatePdf
                certificate={certificate}
                exportId={exportId}
            />
        );
        await downloadPdf(`${certificate._id}.pdf`, Certificate);
    };

    const getXml = (exportId) => {
        const data = {certificate: {export_id: exportId, ...certificate}};
        downloadXml(`${certificate._id}.xml`, data);
    };

    const getJSON = (exportId) => {
        const data = {export_id: exportId, ...certificate};
        downloadJSON(`${certificate._id}.json`, data);
    };

    return (
        <ActionsCell
            getExportId={() => getCertificateExportId(certificate._id)}
            viewHref={`/index/certificate/${certificate._id}`}
            downloadPdf={getPdf}
            downloadXml={getXml}
            downloadJSON={getJSON}
        />
    );
};

export default CertificateActions;
