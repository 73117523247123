import React, {useEffect} from "react";
import {isEmpty, keys, omit} from 'ramda';
import {Grid} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {useStores} from "@core/hooks/useStores";

const Items = ({certificate}) => {
    const {ProductStore} = useStores();

    useEffect(() => {
        if(ProductStore.isLoading || !isEmpty(ProductStore.productsByName)) return;

        ProductStore.getList();
    }, []);

    if (!certificate.items) return null;

    const product = ProductStore.productsByName[certificate.properties.productType] || {};
    const properties = product.properties || [];

    return certificate.items.map((item) => (
        <Grid container spacing={24} wrap="nowrap">
            <Grid item xs={2}>
                {`Q: ${item.quantity}`}
            </Grid>
            {keys(omit(["quantity", "_id"], item)).map((prop) => {
                const property = properties.find((p) => p.name === prop);

                if(!property) return null;

                const {label, measurements} = property;

                return (
                    <Grid item xs={3}>
                        {`${label}: ${item[prop]}${measurements ? measurements.toLowerCase() : ""}`}
                    </Grid>
                );
            })}
        </Grid>
    ));
};

export default observer(Items);