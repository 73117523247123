export default () => ({
    menu: {
        flexDirection: "row",
        boxShadow: "unset",
        border: "1px solid #c4c4c4",
        borderRadius: 4,
        "& a": {
            textDecoration: "none"
        },
        "& button": {
            padding: "12px 50px",
            fontSize: "0.8125rem",
            color: "#000000b3",
            borderBottom: "2px solid transparent",
            borderRadius: 0
        }
    },
    active: {
        "& button": {
            color: "#0288d1",
            borderBottomColor: "#0288d1",
        },
    },
});