import React, {useState} from 'react';
import moment from 'moment';
import {Accordion, AccordionDetails, AccordionSummary, Typography, Grid} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from "@core/components/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import {platform, blockScout} from 'config';
import {ACTION_LABELS} from "@core/constants/actions";
import styles from './styles';

const useStyles = makeStyles(styles);

const ExpandableTimeline = ({transactions}) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(null);

    return (
        <div className={classes.container}>
            {transactions.map((transaction) => (
                <Accordion
                    classes={{
                        root: classes.accordion,
                        expanded: classes.expanded,
                    }}
                    key={transaction._id}
                    expanded={expanded === transaction._id}
                    onChange={(event, expanded) => {
                        setExpanded(expanded ? transaction._id : null);
                    }}
                >
                    <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon/>}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Avatar
                                    className={classes.avatar}
                                    firstName={transaction.signer.firstname}
                                    lastName={transaction.signer.lastname}
                                    src={transaction.signer.avatar ?
                                        `${platform}${transaction.signer.avatar.file.dir}${transaction.signer.avatar?.file.name}` :
                                        null}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <div className={classes.status}>
                                    {ACTION_LABELS[transaction.action].toUpperCase()}
                                    {transaction.signer && <img src="/images/verified-user.png" alt="verified"/>}
                                </div>
                                <div>
                                    {transaction.signer && `${transaction.signer.firstname} ${transaction.signer.lastname}`}
                                    <div className={classes.company}>{transaction.signer.company.name}</div>
                                </div>
                                <div className={classes.date}>
                                    {moment(transaction.date_created).format("MMM Do YYYY")}
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <Typography>
                            Transaction verification
                        </Typography>
                        <div className={classes.signature}>
                            <a target="_blank" href={`${blockScout}/tx/${transaction.signature}`}>
                                {transaction.signature}
                            </a>
                        </div>
                        {transaction.signer.company && (
                            <div className={classes.companyLogo}>
                                {transaction.signer.company.logo ? (
                                    <img
                                        src={platform + transaction.signer.company.logo}
                                        alt="company logo"
                                    />
                                ) : transaction.signer.company.name}
                            </div>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default ExpandableTimeline;
