import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
import styles from "./styles";

const useStyles = makeStyles(styles);

const AusteniteSpacing = ({test}) => {
    const {properties} = test;
    const classes = useStyles();
    return (
      <>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <div className={classes.name}>Test Norms</div>
            <span className={classes.value}>{properties.norm}</span>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.name}>Equiptment</div>
            <span className={classes.value}>{properties.equipment}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <div className={classes.name}>Acceptance criteria</div>
            <span className={classes.value}>{properties.acceptance}</span>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.name}>Etching</div>
            <span className={classes.value}>{properties.etching}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <div className={classes.name}>Sampling</div>
            <span className={classes.value}>{properties.sampling}</span>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.name}>Magnification</div>
            <span className={classes.value}>{properties.magnification}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <Table className={classes.borderTable}>
              <TableHead>
                <TableRow>
                  <TableCell size='small'><b>Field</b></TableCell>
                  <TableCell><b>Austenite spacing (µm)</b></TableCell>
                </TableRow>
              </TableHead>
              {properties.elements && properties.elements.length > 0 ? (
                <TableBody>
                  {properties.elements.map((element ) => (
                    <TableRow key={element.field}>
                      <TableCell>{element.field}</TableCell>
                      <TableCell>{element.spacing}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ): null}
            </Table>
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom}>
          <Grid item xs={12}>
            <Table className={classes.borderTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Mean <b>Austenite (µm)</b></TableCell>
                  <TableCell>Standard deviation</TableCell>
                  <TableCell>CI 95%</TableCell>
                  <TableCell>Relative Accuracy %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classNames({[classes.cellGreen]: properties.meanAustenite})}><b>{properties.meanAustenite || "-"}</b></TableCell>
                  <TableCell><b>{properties.deviation || "-"}</b></TableCell>
                  <TableCell><b>{properties.ci || "-"}</b></TableCell>
                  <TableCell><b>{properties.accuracy || "-"}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </>
    );
}

export default AusteniteSpacing;
