import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const NotesTest = ({test}) => {
    const classes = useStyles();
    return (
      <>
        <Grid container alignContent="flex-start">
          <Grid container alignContent="space-between">
            <Grid item xs={12}>
              <div className={classes.values}>{test.properties.description}</div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

export default withStyles(styles)(NotesTest);
