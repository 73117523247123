import React from 'react';
import ReactDOM from 'react-dom';
import "@core/middleware/setAxiosDefaults";
import {StoresProvider, stores} from "@core/stores"
import App from "./App";

ReactDOM.render(
    <StoresProvider value={stores}>
        <App/>
    </StoresProvider>,
    document.getElementById('root')
);