export default () => ({
  container: {
    display: "flex",
    color: "#575757",
    alignItems: "center",
    "& p": {
      maxWidth: 300,
      textOverflow: "ellipsis",
      marginLeft: 15,
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
});