import React from "react";
import {Backdrop, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const PageLoader = () => {
    const classes = useStyles();
    return (
        <Backdrop open classes={{root: classes.backdrop}}>
            <CircularProgress />
        </Backdrop>
    );
};

export default PageLoader;
