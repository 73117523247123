import axios from "axios";
import {generateRefreshToken} from '@core/api/loginService';
import notifications from '@core/stores/notifications';

const checkTokens = async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    config.headers["X-Token-Jwt"] = accessToken;

    const expiresIn = localStorage.getItem("expiresIn");
    const nowDate = parseInt(new Date().getTime() / 1000, 10);

    if (expiresIn && nowDate > expiresIn) {
        try {
            const tokens = await generateRefreshToken();

            localStorage.setItem("expiresIn", tokens.expiresIn);
            localStorage.setItem("accessToken", tokens.accessToken);
            localStorage.setItem("refreshToken", tokens.refreshToken);

            config.headers["X-Token-Jwt"] = tokens.accessToken;
            return config;

        } catch (err) {
            localStorage.removeItem("expiresIn");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");

            window.location.replace("/login");
        }
    }
    return config;
};

const showError = async (error) => {
    const errorMessage = error.response.data.error || error.response.data;
    notifications.error(errorMessage);
};

const getResponseData = (response) => {
    return response.data;
}

axios.interceptors.request.use(checkTokens);
axios.interceptors.response.use(getResponseData, showError);