import React, {useContext} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {isEmpty} from "ramda";
import {SelectedItemContext} from "@core/components/Table/context";

const useStyles = makeStyles(styles);

const TableHeader = ({name, items, downloadPdf, downloadXml, downloadJSON}) => {
    const classes = useStyles();

    const selected = useContext(SelectedItemContext);

    const selectedItems = items.filter(item => selected.includes(item._id));

    return (
        <>
            <span className={classes.name}>{name}</span>
            <FormControl variant="outlined" margin="dense">
                <InputLabel>Bulk Actions</InputLabel>
                <Select
                    value=""
                    disabled={isEmpty(selected)}
                    className={classes.bulkActions}
                    label="Bulk Actions"
                >
                    <MenuItem
                        value="pdf"
                        onClick={() => downloadPdf(selectedItems)}
                    >
                        PDF
                    </MenuItem>
                    <MenuItem
                        value="xml"
                        onClick={() => downloadXml(selectedItems)}
                    >
                        XML
                    </MenuItem>
                    <MenuItem
                        value="json"
                        onClick={() => downloadJSON(selectedItems)}
                    >
                        JSON
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default TableHeader;
