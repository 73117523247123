export default () => ({
  centered: {
    textAlign: "center",
  },
  tensileTest: {
    display: "flex",
    "& h4": {
      fontWeight: 400,
      color: "#616161",
      marginBottom: 5,
    }
  },
  item: {
    marginTop: 20
  },
  value: {
    color: "#616161",
    fontSize: 16,
    fontWeight: 700
  },
  table: {
    margin: "20px 0",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "20px 0"
  },
  requirements: {
    margin: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
