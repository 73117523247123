import React from 'react';
import {last} from 'ramda';
import OwnerInfo from "../OwnerInfo";

const PreviousOwner = ({certificate}) => {
    const previousOwner = last(certificate.previous_owners);

    return (
        <OwnerInfo company={previousOwner} />
    );
};

export default PreviousOwner;