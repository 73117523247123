export default () => ({
  table: {
    marginBottom: 30,
    "& th": {
      padding: "15px 20px",
      fontSize: 20,
      fontWeight: 700
    },
    "& td": {
      padding: "10px 20px",
      fontSize: 16,
      fontWeight: 400,
    },
    "& td, th": {
      border: "1px solid #e7e7e7"
    },
  },
});
