import React from "react";
import {Grid} from '@material-ui/core';


const ProductInfo = ({certificate}) => {
    const {properties, certificateType, material_grade, tp_UNS} = certificate;
    return (
        <>
            <Grid container>
                <Grid container item xs={3}>
                    <Grid item xs={10}>Product Type</Grid>
                    <Grid item xs={1}>:</Grid>
                </Grid>
                <Grid item xs={8}>
                    <b>{properties ? properties.productType : null}</b>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={10}>Norm</Grid>
                    <Grid item xs={1}>:</Grid>
                </Grid>
                <Grid item xs={8}>
                    <b>{properties ? properties.norm : null}</b>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={10}>Grade / TP UNS</Grid>
                    <Grid item xs={1}>:</Grid>
                </Grid>
                <Grid item xs={8}>
                    <b>{material_grade || tp_UNS}</b>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={10}>Type</Grid>
                    <Grid item xs={1}>:</Grid>
                </Grid>
                <Grid item xs={8}>
                    <b>{certificateType}</b>
                </Grid>
            </Grid>
        </>
    );
}

export default ProductInfo;