export default () => ({
    container: {
      "& > div": {
          marginBottom: 1,
      },
      "& > div:last-child": {
          marginBottom: 0,
      }
    },
    accordion: {
        position: "relative",
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        boxShadow: 'none',
        position: "relative",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    status: {
        "& img": {
            width: 17,
            height: 17,
            position: "absolute",
            top: 11,
            right: "calc(50% - 10px)",
        },
        fontWeight: 400,
        marginBottom: 7,
    },
    avatar: {
        width: 55,
        height: 55,
    },
    company: {
        fontSize: 15,
    },
    date: {
        color: "#c9c9c9",
        fontSize: 13,
        marginTop: 2,
    },
    summary: {
        height: 110,
        color: "white",
        backgroundColor: "#575757",
        "& svg": {
            fill: "#ffffff",
        }
    },
    signature: {
        cursor: "pointer",
        textDecoration: "underline",
        overflowWrap: "break-word",
    },
    details: {
        display: "block",
    },
    companyLogo: {
        display: "flex",
        justifyContent: "flex-end",
        "& img": {
            width: 100,
            height: 35,
        }
    },
});