export default () => ({
  marginBottom: {
    marginBottom: "32px"
  },

  values: {
    fontWeight: 700,
    color: "#616161"
  },

  name: {
    fontWeight: 400,
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: 700,
  },

  cellGreen: {
    color: "#4caf50",
  },

  borderTable: {
    "& td, & th": {
      textAlign: "center",
      padding: "4px 24px",
      border: "1px solid rgba(224, 224, 224, 1)",
    }
  }
});

