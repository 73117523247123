import React from "react";
import {withStyles, Table, TableBody, TableCell, TableRow, Paper, Tooltip} from "@material-ui/core";
import classNames from "classnames";
import styles from "./styles";
import {norms} from './constants';

const BASE_ZONE = "Base";

const ChemicalCompositionTable = (props) => {

  const {table, norm, grade, zone, classes, exceptions} = props;
  const countColumns = 8;
  const tables = Array(Math.ceil(table.length / countColumns)).fill();
  const defaultValues = norms.find((el) => el.value === norm).grades.find((el) => el.value === grade).elements;
  const metalWithException =  table.reduce((acc, curr) => {
    const foundMetal = defaultValues.find((dv) => dv.bm === curr.bm);

    if(foundMetal && (foundMetal.max !== curr.max || foundMetal.min !== curr.min)){
      return [...acc, curr.bm];
    }

    return acc;
  },[]);

  const metalName = {
    Base: "BM",
    Haz: "HAZ",
    Weld: "WM"
  };

  const getDefaultValues = (bm) => {
    const value = defaultValues.find((dv) => dv.bm === bm);

    return `Min: ${value.min}, Max: ${value.max}`;
  };

  const tableCellConstructor = (bm, field, value, label) => {
    const limit = field === "max" ? (value === 0 ? " - " : value) : value;

    if(metalWithException.includes(bm)){
      return (
        <Tooltip title={`Default boundaries of ${bm} are:  ${getDefaultValues(bm)}`} classes={{tooltip: classes.tooltip}}>
          <div className={classes.bold}>{label || value}</div>
        </Tooltip>
      );
    }

    return <div>{label || limit}</div>;
  };

  const formulaTableCell = (el) => {
    const name = el.bm === "Pren" && exceptions !== "" ? "CQ_03" || el.bm : el.bm;

    return (
      <Tooltip title={el.formula} classes={{tooltip: classes.tooltip}} key={el.label}>
        <div className={classes.formula}>{el.label || name}</div>
      </Tooltip>
    );
  };

  return (
    <div>
      {tables.map((nil, tableIndex) => {
        const from = tableIndex * countColumns;
        const to = tableIndex * countColumns + countColumns;

        const tableData = table.slice(from, to);

        return <Table
          style={{width: `${((tableData.length + 1 ) / (countColumns + 1)) * 100  }%`, marginBottom: 40}}
          className={classes.table}
          key={tableIndex}
        >
          <TableBody>
            <TableRow>
              <TableCell />
              {tableData.map((el) => <TableCell key={el.label}>{el.formula ? formulaTableCell(el) : tableCellConstructor(el.bm, "bm", el.bm, el.label)}</TableCell>)}
            </TableRow>
            {zone === BASE_ZONE && (
              <>
                <TableRow>
                  <TableCell>Min</TableCell>
                  {tableData.map((el) => <TableCell key={el.min}>{tableCellConstructor(el.bm, "min", el.min)}</TableCell> )}
                </TableRow>
                <TableRow>
                  <TableCell>Max</TableCell>
                  {tableData.map((el) => <TableCell key={el.max}>{tableCellConstructor(el.bm, "max", el.max)}</TableCell>)}
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell>{metalName[zone] || zone}</TableCell>
              {tableData.map((el) => <TableCell
                key={el.value}
                className={classNames({
                  [classes.greenTitle]: ((el.value >= el.min && el.value <= el.max) || (el.value >= el.min && !el.max)) && zone === BASE_ZONE,
                  [classes.redTitle]: (el.value < el.min || (el.max && el.value > el.max)) && zone === BASE_ZONE,
                  [classes.bold]: metalWithException.includes(el.bm)
                })}>{el.value}</TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>;
      })}
    </div>
  );
};

export default withStyles(styles)(ChemicalCompositionTable);
