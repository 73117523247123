import React from "react";
import {Grid, Table, TableBody, TableRow, TableHead, TableCell} from "@material-ui/core";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const TensileTest = (props) => {
    const test = props.test.properties;
    const classes = useStyles();

    const getRpColor = () => {
        if (test.requirements.rpMax) {
            if (test.rp >= test.requirements.rpMin && test.rp <= test.requirements.rpMax) {
                return "satisfactory";
            }

            return "unsatisfactory";
        }

        if (test.rp >= test.requirements.rpMin) {
            return "satisfactory";
        }

        return "unsatisfactory";

    };
    const getRmColor = () => {
        if (test.requirements.rmMax) {
            if (test.rm >= test.requirements.rmMin && test.rm <= test.requirements.rmMax) {
                return "satisfactory";
            }

            return "unsatisfactory";
        }

        if (test.rm >= test.requirements.rmMin) {
            return "satisfactory";
        }

        return "unsatisfactory";

    };
    const getAColor = () => {
        if (test.acceptanceCriteria === "API 5L") {
            if (test.a >= getCalculatedA()) {
                return "satisfactory";
            }

            return "unsatisfactory";
        }

        if (test.a >= test.requirements.a) {
            return "satisfactory";
        }

        return "unsatisfactory";

    };
    const getCalculatedA = () => parseFloat((1940 * (((test.wTraz * (Math.round(test.thk / 10) * 10)) ** 0.2) / (535 ** 0.9))).toFixed(1));

    return (
        <>
            <Grid container className={classes.tensileTest} spacing={2}>
                <Grid item xs={3} className={classes.item}>
                    <div>Client</div>
                    <span className={classes.value}>{test.client || "-"}</span>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    <div>Laboratory</div>
                    <span className={classes.value}>{test.lab || "-"}</span>
                </Grid>
            </Grid>
            <Grid container className={classes.tensileTest} spacing={2}>
                <Grid item xs={3} className={classes.item}>
                    <div>Acceptance criteria</div>
                    <span className={classes.value}>{test.acceptanceCriteria}</span>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    <div>Limit</div>
                    <span className={classes.value}>{test.limit}</span>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    <div>Specimen</div>
                    <span className={classes.value}>{test.specimen}</span>
                </Grid>
            </Grid>

            {/* ATTENTION */}
            {/* this view is for an old tensile test (without FieldArray), in order to maintain old tests */}

            {!test.elements && (
                <>
                    <Grid container className={classes.tensileTest} spacing={2}>
                        <Grid item xs={3} className={classes.item}>
                            <div>Position</div>
                            <span className={classes.value}>{test.position}</span>
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <div>Orientation</div>
                            <span className={classes.value}>{test.orientation || " - "}</span>
                        </Grid>
                        <Grid item xs={2} className={classes.item}>
                            <div>Specimen ID</div>
                            <span className={classes.value}>{test.specimenId || " - "}</span>
                        </Grid>
                        {test.strip !== "" && (
                            <Grid item xs={2} className={classes.item}>
                                <div>Strip test</div>
                                <span className={classes.value}>{test.strip} mm</span>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container className={classes.tensileTest} spacing={2}>
                        <Grid item xs={4} className={classes.item}>
                            <div>Test norms</div>
                            <span className={classes.value}>{test.testNorms}</span>
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <div>Test Method</div>
                            <span className={classes.value}>{test.testMethod}</span>
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <div>Zone</div>
                            <span className={classes.value}>{test.zone || " - "}</span>
                        </Grid>
                    </Grid>
                    {test.zone === "Weld" && (
                        <Grid container className={classes.tensileTest} spacing={2}>
                            <Grid item xs={3} className={classes.item}>
                                <div>Fracture Appearance</div>
                                <span className={classes.value}>{test.fracturAppearance || " - "}</span>
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <div>Break position</div>
                                <span className={classes.value}>{test.breakPosition || " - "}</span>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container className={classes.tensileTest} spacing={2}>
                        <Grid item xs={3} className={classes.item}>
                            <div>Dimensions</div>
                            <span className={classes.value}>{test.dimensionX}mm * {test.dimensionY}mm</span>
                        </Grid>
                        <Grid item xs={2} className={classes.item}>
                            <div>Max Load</div>
                            <span className={classes.value}>{Number(test.maxLoad).toFixed(2)}</span>
                        </Grid>
                    </Grid>
                </>
            )}
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell className={classes.centered}>Specimen ID</TableCell>
                        <TableCell className={classes.centered}>Yield Strength</TableCell>
                        <TableCell className={classes.centered}>Tensile Strength, MPA</TableCell>
                        <TableCell className={classes.centered}>Elongation, A%</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Requirements</TableCell>
                        <TableCell/>
                        <TableCell className={classes.centered}>
                            <Grid container spacing={2} justify={"center"}>
                                <Grid item xs={6} className={classes.requirements}>
                                    {test.requirements.rpMin && <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            Min
                                        </Grid>
                                        <Grid item xs={12}>
                                            {test.requirements.rpMin || "-"}
                                        </Grid>
                                    </Grid>}
                                    {test.requirements.rpMax && <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            Max
                                        </Grid>
                                        <Grid item xs={12}>
                                            {test.requirements.rpMax || "-"}
                                        </Grid>
                                    </Grid>}
                                    {!test.requirements.rpMin && !test.requirements.rpMax && "-"}
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell className={classes.centered}>
                            <Grid container spacing={2} justify={"center"}>
                                <Grid item xs={6} className={classes.requirements}>
                                    {test.requirements.rmMin && <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            Min
                                        </Grid>
                                        <Grid item xs={12}>
                                            {test.requirements.rmMin || "-"}
                                        </Grid>
                                    </Grid>}
                                    {test.requirements.rmMax && <Grid container spacing={2}>
                                        {test.requirements.rmMax && <Grid item xs={12}>
                                            Max
                                        </Grid>}
                                        <Grid item xs={12}>
                                            {test.requirements.rmMax || "-"}
                                        </Grid>
                                    </Grid>}
                                    {!test.requirements.rmMin && !test.requirements.rmMax && "-"}
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell className={classes.centered}>
                            {test.acceptanceCriteria === "API 5L" ? getCalculatedA() : test.requirements.a}
                        </TableCell>
                    </TableRow>
                    {test.elements.map((element, index) => (
                        <TableRow key={element.specimenId}>
                            <TableCell>
                                {`Result ${index + 1}`}
                            </TableCell>
                            <TableCell className={classes.centered}>
                                {element.specimenId}
                            </TableCell>
                            <TableCell className={classNames(classes.centered, getRpColor())}>
                                {element.rp || test.rp}
                            </TableCell>
                            <TableCell className={classNames(classes.centered, getRmColor())}>
                                {element.rm || test.rm}
                            </TableCell>
                            <TableCell className={classNames(classes.centered, getAColor())}>
                                {element.a || test.a}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <>
                {test.elements && (
                    test.elements.map((el, index) => (
                        <React.Fragment key={el.specimenId}>
                            {index !== 0 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.divider}/>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container className={classes.tensileTest} spacing={2}>
                                <Grid item xs={3} className={classes.item}>
                                    <div>Position</div>
                                    <span className={classes.value}>{el.position}</span>
                                </Grid>
                                <Grid item xs={3} className={classes.item}>
                                    <div>Orientation</div>
                                    <span className={classes.value}>{el.orientation || " - "}</span>
                                </Grid>
                                <Grid item xs={2} className={classes.item}>
                                    <div>Specimen ID</div>
                                    <span className={classes.value}>{el.specimenId || " - "}</span>
                                </Grid>
                                {el.strip !== "" && (
                                    <Grid item xs={2} className={classes.item}>
                                        <div>Strip test</div>
                                        <span className={classes.value}>{el.strip} mm</span>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container className={classes.tensileTest} spacing={2}>
                                <Grid item xs={4} className={classes.item}>
                                    <div>Test norms</div>
                                    <span className={classes.value}>{el.testNorms}</span>
                                </Grid>
                                <Grid item xs={3} className={classes.item}>
                                    <div>Test Method</div>
                                    <span className={classes.value}>{el.testMethod}</span>
                                </Grid>
                                <Grid item xs={3} className={classes.item}>
                                    <div>Zone</div>
                                    <span className={classes.value}>{el.zone || " - "}</span>
                                </Grid>
                            </Grid>
                            {el.zone === "Weld" && (
                                <Grid container className={classes.tensileTest} spacing={2}>
                                    <Grid item xs={3} className={classes.item}>
                                        <div>Fracture Appearance</div>
                                        <span className={classes.value}>{el.fracturAppearance || " - "}</span>
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <div>Break position</div>
                                        <span className={classes.value}>{el.breakPosition || " - "}</span>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container className={classes.tensileTest} spacing={2}>
                                <Grid item xs={3} className={classes.item}>
                                    <div>Dimensions</div>
                                    <span className={classes.value}>{el.dimensionX}mm * {el.dimensionY}mm</span>
                                </Grid>
                                <Grid item xs={2} className={classes.item}>
                                    <div>Max Load</div>
                                    <span className={classes.value}>{Number(el.maxLoad).toFixed(2)}</span>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))
                )}
            </>
        </>
    );
}

export default TensileTest;
