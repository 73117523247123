import React from 'react';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {Tooltip, Typography, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const AttachIcon = () => {
    const classes = useStyles();

    return (
        <>
            <Tooltip
                classes={{
                    tooltip: classes.tooltip,
                    tooltipPlacementRight: classes.tooltipPlacementRight,
                }}
                placement="right-start"
                title={(
                    <Grid container>
                        <Grid item xs={3} container alignItems="center" justify="center">
                            <img width="60" src="/images/clamav-trademark.png" alt="clamav-trademark"/>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>Scanned with</Typography>
                            <Typography className={classes.description} variant="h6" component="h6">CLAMAV</Typography>
                            <Typography className={classes.description} variant="h6" component="h6">(Virus DB age - 1
                                hour)</Typography>
                        </Grid>
                    </Grid>
                )}
            >
                <AttachFileIcon className={classes.item}/>
            </Tooltip>
            <VerifiedUserOutlinedIcon className={classes.verifiedImage}/>
        </>
    );
}

export default AttachIcon;