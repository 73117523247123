import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {isEmpty, pick} from "ramda";
import {useStores} from "@core/hooks/useStores";
import notifications from '@core/stores/notifications';
import {STATUS_NAME_BY_STATUS, STATUSES} from '@core/constants/orders';
import CertificateForm from '../CertificateForm';

const UploadFiles = ({status, productType, lineItem, order, certificateId, certificateType, projectId}) => {
    const {CertificatesStore, OrdersStore, UploadsStore, ProductStore} = useStores();

    useEffect(() => {
        if(!certificateId || CertificatesStore.certificatesById[certificateId]) return;

        CertificatesStore.getCertificate(certificateId);
    }, [certificateId])

    useEffect(() => {
        if(ProductStore.isLoading || !isEmpty(ProductStore.productsByName)) return;

        ProductStore.getList();
    }, []);

    const createCertificate = async (certificateData) => {
        const data = {
            certificate: certificateData,
            orderId: order._id,
            orderNumber: order.orderNumber,
            lineItem,
            projectId,
        };
        const certificate = await CertificatesStore.createCertificate(data);
        await OrdersStore.updateLineItem(order._id, lineItem, {
            certificate: certificate._id,
            status: STATUS_NAME_BY_STATUS[STATUSES.PENDING]
        });
    };

    const updateCertificate = async (certificateData) => {
        const data = {
            certificate: certificateData,
            orderNumber: order.orderNumber,
            lineItem,
        };
        await CertificatesStore.updateCertificate(data, certificateId);
    }

    const onSubmit = async (values) => {
        const certificate = {
            ...values,
            certificateType,
            properties: {
                ...values.properties,
                productType,
            },
        };

        if(!certificateId) await createCertificate(certificate);
        else await updateCertificate(certificate);

        notifications.success();
    };

    const certificateData = CertificatesStore.certificatesById[certificateId] || {};
    const lineItemUploads = UploadsStore.lineItemUploads[`${order.orderNumber}.${lineItem}`] || [];
    const product = productType && ProductStore.productsByName[productType] || {};

    const certificate = pick(["heat", "material_grade", "tp_UNS", "properties", "items", "_id"], certificateData);

    return (
        <CertificateForm
            lineItemUploads={lineItemUploads}
            disabled={status === STATUS_NAME_BY_STATUS[STATUSES.ACCEPTED]}
            certificate={certificate}
            orderNumber={order.orderNumber}
            properties={product.properties || []}
            lineItem={lineItem}
            onSubmit={onSubmit}
        />
    );
};

export default observer(UploadFiles);
