import { makeObservable, observable, action } from "mobx"

const DEFAULT_ERROR = "Sorry, something went wrong";
const DEFAULT_SUCCESS = "Success";

class NotificationsStore {
    notifications = [];

    constructor() {
        makeObservable(this, {
            notifications: observable,
            remove: action,
            append: action,
            error: action,
            info: action,
            warning: action,
            success: action,
        })
    }

    remove(key) {
        this.notifications = this.notifications.filter(notification => notification.key !== key);
    }

    append(message, severity) {
        this.notifications = [...this.notifications, {
            message,
            severity,
            key: new Date().getTime() + Math.random(),
        }];
    }

    error(message = DEFAULT_ERROR) {
        this.append(message, "error");
    }

    info(message) {
        this.append(message, "info");
    }

    warning(message) {
        this.append(message, "warning");
    }

    success(message = DEFAULT_SUCCESS) {
        this.append(message, "success");
    }
}
export default new NotificationsStore();