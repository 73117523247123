export default () => ({
    popover: {
        padding: "5px 0",
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    popoverHeader: {
        marginLeft: 20,
    },
    file: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
    }
});