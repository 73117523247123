import React from "react";
import {drop, head} from "ramda";
import {observer} from "mobx-react-lite";
import {Grid, Typography, withStyles, makeStyles} from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useStores} from "@core/hooks/useStores";
import Form from "../Form";
import Message from "../Message";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Accordion = withStyles({
    root: {
        padding: "5px 10px",
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        padding: 0,
        border: 0,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        display: "block",
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        border: 0,
        padding: "10px 20px",
        display: "block",
    },
})(MuiAccordionDetails);

const FeedbackList = ({orderId, lineItem}) => {
    const classes = useStyles();

    const {FeedbackStore} = useStores();

    const feedback = FeedbackStore.lineItemFeedback[`${orderId}.${lineItem}`] || [];

    return (
        <>
            {feedback.map((f, feedbackIndex) => {
                const messageHistory = drop(1, f.messages);

                return (
                    <div key={f._id} className={classes.container}>
                        <Typography component="h5" variant="h6">
                            {f.type}
                        </Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={messageHistory.length ? <ExpandMoreIcon/> : null}>
                                <Message message={head(f.messages)} />
                                {!messageHistory.length && (
                                    <Form
                                        lineItem={lineItem}
                                        orderId={orderId}
                                        feedback={feedbackIndex}
                                    />
                                )}
                            </AccordionSummary>
                            {Boolean(messageHistory.length) && (
                                <AccordionDetails>
                                    {messageHistory.map((m, index) => (
                                        <div key={m._id}>
                                            <Grid item xs className={classes.message}>
                                                <Message message={m} />
                                            </Grid>
                                            {index + 1 === messageHistory.length && (
                                                <Form
                                                    lineItem={lineItem}
                                                    orderId={orderId}
                                                    feedback={feedbackIndex}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </AccordionDetails>
                            )}
                        </Accordion>
                    </div>
                );
            })}
        </>
    );
};

export default observer(FeedbackList);