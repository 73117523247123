import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {map, prop, splitEvery, head} from 'ramda';
import {IconButton, Popover, Tooltip, Checkbox, FormControlLabel, Grid, FormGroup, Divider} from "@material-ui/core";
import TocIcon from '@material-ui/icons/Toc';
import styles from './styles';

const useStyles = makeStyles(styles);

const ColumnsSelect = ({columns, selectedColumns, setSelectedColumns}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onAllCheckboxClick = () => {
        if (selectedColumns.length === columns.length) {
            setSelectedColumns([head(columns).dataIndex]);
        } else {
            setSelectedColumns(map(prop('dataIndex'), columns));
        }
    }

    const onCheckboxClick = (dataIndex) => {
        const isColumnSelected = selectedColumns.includes(dataIndex);
        if (isColumnSelected) {
            setSelectedColumns(selectedColumns.filter(column => column !== dataIndex));
        } else {
            setSelectedColumns(selectedColumns.concat(dataIndex));
        }
    }

    return (
        <>
            <Tooltip title="Columns">
                <IconButton
                    className={classes.button}
                    onClick={handlePopoverOpen}
                >
                    <TocIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                onClose={handlePopoverClose}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container>
                    <Grid item xs={12} className={classes.checkboxContainer}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    onChange={onAllCheckboxClick}
                                    checked={selectedColumns.length === columns.length}
                                    indeterminate={!!selectedColumns.length && selectedColumns.length < columns.length}
                                    color="primary"
                                />
                            )}
                            label="Toggle all"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {splitEvery(5, columns).map((chunk, index) => (
                        <Grid item xs key={index} className={classes.checkboxContainer}>
                            <FormGroup>
                                {chunk.map(column => (
                                    <FormControlLabel
                                        key={column.dataIndex}
                                        control={(
                                            <Checkbox
                                                disabled={selectedColumns.length === 1 && selectedColumns.includes(column.dataIndex)}
                                                color="primary"
                                                onClick={() => onCheckboxClick(column.dataIndex)}
                                                checked={selectedColumns.includes(column.dataIndex)}
                                            />
                                        )}
                                        label={column.title}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </>
    );
};

export default ColumnsSelect;
