import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import classNames from "classnames";
import styles from "./styles";

const BendTest = ({test, classes}) => {
  const elements = test.properties.elements || [test.properties];

  return (
    <Grid container alignContent="space-between">
      <Grid item xs={6}>
        {elements.map((element, idx) => {
          return (
            <Grid className={classes.elements} key={idx}>
              <Grid container className={classes.row}><span className={classes.field}>Test {idx + 1}</span></Grid>
              <Grid container alignContent="space-between" className={classes.row} spacing={2}>
                <Grid item xs={4}>
                  <div className={classes.label}>Specimen Id:</div>
                  <span className={classes.field}>{element.specimenId}</span>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.label}>Position:</div>
                  <span className={classes.field}>{element.position}</span>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.label}>Jig:</div>
                  <span className={classes.field}>{`${element.jig} MM`}</span>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.label}>Angle:</div>
                  <span className={classes.field}>{`${element.angle} °`}</span>
                </Grid>
              </Grid>
              <Grid container alignContent="space-between" className={classes.row} spacing={2}>
                <Grid item xs={4}>
                  <div className={classes.label}>Dimensions:</div>
                  <span className={classes.field}>{`${element.width} X ${element.height} MM`}</span>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.label}>Roll Distance:</div>
                  <span className={classes.field}>{`${element.rollsDistance} MM`}</span>
                </Grid>
              </Grid>
              <Grid container justify={"flex-end"} className={classes.row}>
                <span className={classNames({
                  [classes.greenField]: element.result === "Not defective",
                  [classes.redField]: element.result === "Defective"
                })}>{element.result}</span>
              </Grid>
            </Grid>
          );
        })}

        <Grid className={classes.row}>
          <div className={classes.label}>Notes:</div>
          <span className={classes.field}>{test.properties.notes}</span>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction={"column"} alignContent={"center"}>
          <Grid item className={classes.row}>
            <div className={classes.label}>Test norms:</div>
            <span className={classes.field}>{test.properties.testStandard}</span>
            <span className={classes.field}>{test.properties.relatedStandard}</span>
          </Grid>
          {test.properties.client && <Grid item className={classes.row}>
            <div className={classes.label}>Client:</div>
            <span className={classes.field}>{test.properties.client}</span>
          </Grid>}
          {test.properties.lab && <Grid item className={classes.row}>
            <div className={classes.label}>Lab:</div>
            <span className={classes.field}>{test.properties.lab}</span>
          </Grid>}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default withStyles(styles)(BendTest);
