import React, {useState, useRef} from 'react';
import {Button, Popover} from "@material-ui/core";
import {ClickAwayListener} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const DownloadPopover = ({downloadPdf, downloadXml, downloadJSON, getExportId}) => {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);

    const classes = useStyles();

    const onDownload = async (download) => {
        const exportId = await getExportId();
        download(exportId);
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Button
                    ref={anchorEl}
                    onClick={() => setOpen(!open)}
                >
                    Download
                </Button>
            </ClickAwayListener>
            <Popover
                classes={{
                    paper: classes.popover,
                }}
                anchorEl={anchorEl.current}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Button
                    size="large"
                    onClick={() => onDownload(downloadPdf)}
                >
                    Pdf
                </Button>
                <Button
                    size="large"
                    onClick={() => onDownload(downloadXml)}
                >
                    xml
                </Button>
                <Button
                    size="large"
                    onClick={() => onDownload(downloadJSON)}
                >
                    json
                </Button>
            </Popover>
        </>
    );
};

export default DownloadPopover;