import React from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

import styles from "./styles";

const useStyles = makeStyles(styles);

const NotFoundPage = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img
                className={classes.notFoundImage}
                src="/images/error-404.gif"
                alt="Page is not found"
            />
        </div>
    );
};

export default NotFoundPage;
