import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import RadioGraphicTable from "./table";
import styles from "./styles";

const useStyles = makeStyles(styles);

const RadioGraphic = (props) => {
    const test = props.test;
    const classes = useStyles();
    return (
        <div className={classes.radioGraphic}>
            <Grid container justify="space-between" spacing={2}>
                <Grid item xs={3}>
                    <div>Workstage</div>
                    <span className={classes.values}>{test.properties.workStage}</span>
                </Grid>
                <Grid item xs={3}>
                    <div>Radioscopic Tec.</div>
                    <span className={classes.values}>{test.properties.radioscopic}</span>
                </Grid>
                <Grid item xs={3}>
                    <div>Test norms</div>
                    <span className={classes.values}>{test.properties.norm}</span>
                </Grid>
                <Grid item xs={3}>
                    <div>Acceptance criteria</div>
                    <span className={classes.values}>{test.properties.acceptance}</span>
                </Grid>
            </Grid>
            <h2>Single exposure and viewing</h2>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <div className={classes.source}>
                        <div>Source</div>
                        <span>{test.properties.source}</span>
                        <div>Size: <span className={classes.values}>{`${test.properties.sourceSize} MM`}</span></div>
                        <div>Distance: <span className={classes.values}>{`${test.properties.sourceDistance} MM`}</span>
                        </div>
                    </div>
                    <div className={classes.source}>
                        <div>Screen</div>
                        <div>Type: <span className={classes.values}>{test.properties.screen}</span></div>
                        <div>Thickness Source Side: <span
                            className={classes.values}>{`${test.properties.thicknessSourceSide} MM`}</span></div>
                        <div>Thickness Film Side: <span
                            className={classes.values}>{`${test.properties.thicknessFilmSide} MM`}</span></div>
                    </div>
                </Grid>
                <Grid item xs={7} className={classes.additional}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div>Position</div>
                            <span className={classes.values}>{test.properties.position}</span>
                            <div>Density</div>
                            <span className={classes.values}>{test.properties.density}</span>
                            <div>Film Type</div>
                            <span className={classes.values}>{test.properties.filmType}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <div>X-ray Apparatus</div>
                            <span className={classes.values}>{test.properties.xRayApparatus}</span>
                            <div>I.Q.I Type</div>
                            <span className={classes.values}>{test.properties.iqiType}</span>
                            <div>Sales order №</div>
                            <span className={classes.values}>{test.properties.salesOrderNumber}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={10} className={classes.description}>
                <div>Description</div>
                <div>{test.properties.description}</div>
            </Grid>
            <RadioGraphicTable table={test.properties.sections}/>
        </div>
    );
}

export default RadioGraphic;
