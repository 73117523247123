import React from "react";
import {TextField} from "@material-ui/core";
import {useField} from "formik";
import {trim} from "ramda";

const Input = ({helperText, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const {error, touched} = meta;
    const {setTouched, setValue} = helpers;

    const showError = Boolean(error) && touched;

    return (
        <TextField
            value={field.value}
            error={showError}
            helperText={showError ? error : null}
            onChange={({target}) => {
                if (!touched) setTouched(true);

                setValue(target.value);
            }}
            onBlur={() => setValue(trim(field.value.toString() || ""))}
            {...props}
        />
    );
};

export default Input;