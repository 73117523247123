import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useLocation} from 'react-router-dom';
import {useStores} from "@core/hooks/useStores";
import ErrorBoundary from "@core/components/ErrorBoundary";
import Header from "./Header";
import Menu from './Menu';
import styles from "./styles";

const useStyles = makeStyles(styles);

const isMenuOpen = localStorage.getItem("isMenuOpen") === "true";

const Layout = ({children}) => {
    const classes = useStyles();

    const location = useLocation();

    const {UsersStore} = useStores();

    const [menuOpen, setOpen] = useState(isMenuOpen);

    useEffect(() => {
        UsersStore.getUser();
    }, []);

    const toggleMenu = () => {
        const value = !menuOpen;
        setOpen(value);
        localStorage.setItem("isMenuOpen", JSON.stringify(value));
    };

    return (
        <div className={classes.root}>
            <Menu
                toggleMenu={toggleMenu}
                open={menuOpen}
            />
            <div className={classes.pageContainer}>
                <Header open={menuOpen}/>
                <ErrorBoundary key={location.pathname}>
                    <main className={classes.content}>
                        {children}
                    </main>
                </ErrorBoundary>
            </div>
        </div>
    );
}

export default Layout;