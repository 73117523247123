import {createMuiTheme} from "@material-ui/core/styles";
import {green, grey, lightBlue, pink} from "@material-ui/core/colors";

export default createMuiTheme({
    palette: {
        primary: {
            main: lightBlue[700],
        },
        secondary: {
            light: pink[300],
            main: pink[500],
            dark: pink[700]
        },
        success: {
            main: green[500],
        },
        error: {
            main: "#d02d52"
        },
        warning: {
            main: "#d4a237"
        },
        text: {
            primary: grey[700],
        },
    },
    typography: {
        fontFamily: "Inter",
    },
});