export default (theme) => ({
    root: {
        display: 'flex',
    },
    pageContainer: {
        width: "100%",
        height: "100%",
    },
    content: {
        padding: theme.spacing(3),
    }
});