import { makeObservable, observable, action } from "mobx"
import axios from "axios";
import {indexBy, prop} from "ramda";
import {api} from "config";

class CertificatesStore {
    isLoaded = false;
    certificatesById = {};

    constructor() {
        makeObservable(this, {
            isLoaded: observable,
            certificatesById: observable,
            getList: action,
            getCertificate: action,
        })
    }

    async getList() {
        const response = await axios({
            method: "get",
            url: `${api}/certificates/list`,
        });

        this.certificatesById = indexBy(prop('_id'), response);
        this.isLoaded = true;
    }

    async getCertificate(certificateId) {
        const response = await axios({
            method: "get",
            url: `${api}/certificates/single`,
            params: {certificateId}
        });

        const [certificate] = response;
        if(!certificate) return;

        this.certificatesById[certificateId] = certificate;
    }

    async createCertificate(data) {
        const response = await axios({
            method: "post",
            url: `${api}/certificates`,
            data,
        });

        this.certificatesById[response._id] = response;
        return response;
    }

    async updateCertificate(data, id) {
        const response = await axios({
            method: "put",
            url: `${api}/certificates/${id}`,
            data,
        });

        this.certificatesById[id] = response;
        return response;
    }
}
export default new CertificatesStore();