export default () => ({
  values: {
    fontFamily: "Gotham Bold, sans-serif",
    fontWeight: "400",
    color: "#616161"
  },

  greenValidate: {
    color: "#4caf50",
    fontWeight: "600",
  },

  redValidate: {
    color: "#f44336",
    fontWeight: "600",
  }
});

