export default {
    statistics: {
        marginTop: 30,
        textAlign: "center",
        color: "#fff",
        "& p": {
            margin: 0,
        },
        "& div p:first-child": {
            fontSize: 60,
            fontWeight: "bold",
        },
        "& div p:last-child": {
            fontSize: 20,
        }
    }
};