import React from 'react';
import Certificate from "@core/components/Certificate";
import Test from '@core/components/Test';
import {sortTestsByType} from "@core/helpers";

const CertificatePdf = ({certificate, exportId}) => {
    const sortedTests = sortTestsByType(certificate.tests);
    return (
        <div>
            <div style={{pageBreakBefore: "always"}} />
            <div style={{textAlign: "right", marginBottom: 20, fontSize: 20}}>
                Export ID: {exportId}
            </div>
            <Certificate certificate={certificate} tests={sortedTests} />
            {sortedTests.map((test, index) => (
                <>
                    <div style={{pageBreakBefore: "always"}} />
                    <Test
                        key={test._id}
                        test={test}
                        expandable={false}
                        isLast={index === sortedTests.length - 1}
                    />
                </>
            ))}
        </div>
    );
}

export default CertificatePdf;
