export default (theme) => ({
    notFoundImage: {
        height: "calc(100vh - 60px)",
    },
    container: {
        margin: -theme.spacing(3),
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
    }
});