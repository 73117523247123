import React from 'react';
import {withStyles} from '@material-ui/core';
import {api} from "config";
import {getFileName, getFileExtension} from '@core/helpers';
import styles from './styles';

const configIconsForExt = {
  "zip": "/images/files/ppt/zip",
  "pptx": "/images/files/ppt.svg",
  "ppt": "/images/files/ppt.svg",
  "jpeg": "/images/files/jpg.svg",
  "jpg": "/images/files/jpg.svg",
  "png": "/images/files/png.svg",
  "pdf": "/images/files/pdf.svg",
  "xls": "/images/files/xls.svg",
  "xlsx": "/images/files/xls.svg",
  "doc": "/images/files/doc.svg",
  "docx": "/images/files/doc.svg",
  "txt": "/images/files/txt.svg"
};

const File = ({fileName, dir, classes}) => {
  const name = getFileName(fileName);
  const extension = getFileExtension(fileName);

  const src = configIconsForExt[extension] || "/images/files/file.svg";

  return (
    <a
      href={`${api}${dir}${fileName}`}
      download
      target="_blank"
      className={classes.container}
    >
      <img width="40" height="40" src={src} alt={"download file"} />
      <p>{name}</p>
    </a>
  );
};

export default withStyles(styles)(File);
