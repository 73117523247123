export default (theme) => ({
  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },

  greenTitle: {
    color: theme.palette.success
  },

  redTitle: {
    color: theme.palette.error.main
  },
  radioValues: {
    textTransform: "uppercase",
  },
  hardnessTest: {
    "& table": {
      width: "auto",
      marginBottom: 25,
      overflowX: "hidden",
      tableLayout: "fixed",
      marginTop: 24,
      "& tbody": {
        borderColor: "C9C9C9",
        "& tr": {
          maxWidth: "100%"
        },
        "& td": {
          textAlign: "center",
          padding: "0 10px",
          width: 80,
        }
      },
    },
  },
  bold: {
    fontWeight: 500,
  },
  green: {
    color: theme.palette.success
  },
  materials: {
    "& h1": {
      marginBottom: 25,
    },

    "& h4": {
      fontWeight: 400,
      paddingTop: 15,
      marginBottom: 0,
      color: "#616161",
    },

    "& span": {
      fontWeight: 700,
      color: "#616161",
      paddingTop: 5,
      display: "block",
    },
  }
});

