import React, {Component} from "react";
import {Grid, withStyles} from "@material-ui/core";
import HardnessTable from "./table";
import styles from "./styles";

class HardnessTest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "all",
      tableData: props.test.properties.elements
    };
  }

  render() {
    const {test, classes} = this.props;

    return (<>
      <div className={classes.hardnessTest}>
        <div className={classes.materials}>
          <Grid container alignContent="space-between">
            {test.properties.norm !== "ASTM E92-16" && (
              <Grid item xs={6}>
                <img style={{maxWidth: "85%"}} src="/images/hardness-chart.png" alt=""/>
              </Grid>
            )}
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <h4>Client:</h4>
                  <span>{test.properties.client || "-"}</span>
                </Grid>
                <Grid item xs={6}>
                  <h4>Lab:</h4>
                  <span>{test.properties.lab || "-"}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <h4>Test norms:</h4>
                  <span>{test.properties.norm}</span>
                </Grid>
                <Grid item xs={6}>
                  <h4>Acceptance criteria:</h4>
                  <span>{test.properties.acceptance}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <h4>Hardness test:</h4>
                  <span>{test.properties.hardnessTest || "-"}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {this.state.tableData && (
            <HardnessTable table={this.state.tableData}/>
        )}
      </div>
    </>);
  }
}

export default withStyles(styles)(HardnessTest);
