import React from "react";
import {Grid, Table, TableBody, TableRow, TableCell} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import styles from "./styles";

const useStyles = makeStyles(styles);

const UltrasonicTest = ({test}) => {
    const classes = useStyles();
    const {properties} = test;

    return (
        <>
            <Grid container justify="space-between" spacing={2} className={classes.marginBottom}>
                <Grid item xs={6}>
                    <div className={classes.name}>Test Norms</div>
                    <span className={classes.value}> {properties.norm}</span>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.name}>Acceptance criteria</div>
                    <span className={classes.value}>{properties.acceptance}</span>
                </Grid>
            </Grid>
            <Grid container justify="space-between" className={classes.marginBottom}>
                <Grid item xs={6}>
                    <div className={classes.name}>Surface Preparation</div>
                    <span className={classes.value}> {properties.surface_preparation}</span>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.name}>Result</div>
                    <span className={classes.value}>{properties.result}</span>
                </Grid>
            </Grid>
            <Grid container justify="space-between" className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Examination conditions</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Scanning speed</TableCell>
                                <TableCell>{properties.examination.speed}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Couplant Gel</TableCell>
                                <TableCell>{properties.examination.gel}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Scanning overlap minimum</TableCell>
                                <TableCell>{properties.examination.overlap}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Equipment and examination technique</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Equipment</TableCell>
                                <TableCell>{properties.technique.equipment}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Unit cable</TableCell>
                                <TableCell>{properties.technique.cable}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Technique</TableCell>
                                <TableCell>{properties.technique.technique}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Sensitivity</TableCell>
                                <TableCell>{properties.technique.sensitivity}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Scanned volume</TableCell>
                                <TableCell>{properties.technique.volume}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Probes and Calibration</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Waves</TableCell>
                                <TableCell>{properties.probes.waves}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Angle</TableCell>
                                <TableCell>{properties.probes.angle}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Brand and model</TableCell>
                                <TableCell>{properties.probes.model}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Size</TableCell>
                                <TableCell>{properties.probes.size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Frequence</TableCell>
                                <TableCell>{properties.probes.frequence}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Calibration</TableCell>
                                <TableCell>{properties.probes.calibration}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container justify="space-between">
                <Grid item xs={12}>
                    <div className={classes.values}>{properties.notes}</div>
                </Grid>
            </Grid>
        </>
    );
}

export default UltrasonicTest;
