import {createContext} from "react";
import StatisticsStore from './statistics';
import UsersStore from './users';
import TestsStore from './tests';
import CertificatesStore from "./certificates";
import OrdersStore from "./orders";
import NotificationsStore from "./notifications";
import UploadsStore from './uploads';
import FeedbackStore from './feedback';
import ProductStore from './products';

export const stores = Object.freeze({
    StatisticsStore,
    UsersStore,
    CertificatesStore,
    TestsStore,
    OrdersStore,
    NotificationsStore,
    UploadsStore,
    FeedbackStore,
    ProductStore,
});

export const storesContext = createContext(stores);
export const StoresProvider = storesContext.Provider;
