import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ChemicalCompositionTable from "./table";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ChemicalComposition = ({test}) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className="materials">
                <Grid container alignContent="space-between" className={classes.infoRow}>
                    <Grid item xs={3}>
                        <div>Client:</div>
                        <span className={classes.infoCell}>{test.properties.client || "-"}</span>
                    </Grid>
                    <Grid item xs={3}>
                        <div>Laboratory:</div>
                        <span className={classes.infoCell}>{test.properties.lab || "-"}</span>
                    </Grid>
                    <Grid item xs={3}>
                        <div>Test standard:</div>
                        <span className={classes.infoCell}>{test.properties.testStandard || "-"}</span>
                    </Grid>
                    <Grid item xs={3}>
                        <div>Zone:</div>
                        <span className={classes.infoCell}>{test.properties.zone}</span>
                    </Grid>
                </Grid>
                <Grid container alignContent="space-between" className={classes.infoRow}>
                    <Grid item xs={3}>
                        <div>Norm:</div>
                        <span className={classes.infoCell}>{test.properties.norm}</span>
                    </Grid>
                    <Grid item xs={3}>
                        <div>Product name:</div>
                        <span className={classes.infoCell}>{test.properties.name}</span>
                    </Grid>
                    <Grid item xs={3}>
                        <div>Grade:</div>
                        <span className={classes.infoCell}>{test.properties.grade}</span>
                    </Grid>
                    {test.properties.exceptions && (
                        <Grid item xs={3}>
                            <div>Exception:</div>
                            <span className={classes.infoCell}>{test.properties.exceptions}</span>
                        </Grid>
                    )}
                </Grid>
            </div>
            <ChemicalCompositionTable
                table={test.properties.elements}
                norm={test.properties.norm}
                grade={test.properties.grade}
                zone={test.properties.zone}
                exceptions={test.properties.exceptions}
            />
        </div>
    );

}

export default ChemicalComposition;
