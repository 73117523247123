import React, {useState} from 'react';
import {isEmpty} from "ramda";
import {makeStyles} from "@material-ui/core/styles";
import OwnerInfo from "../OwnerInfo";
import OwnersHistoryModal from "../OwnersHistoryModal";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Owner = ({certificate}) => {
    const [showOwners, setShowOwners] = useState(false);
    const classes = useStyles();
    return (
        <>
            <OwnerInfo company={certificate.company}/>
            {certificate.previous_owners && !isEmpty(certificate.previous_owners) && (
                <div
                    className={classes.previousOwner}
                    onClick={() => setShowOwners(!showOwners)}
                >
                    Previous owners
                </div>
            )}
            <OwnersHistoryModal
                certificate={certificate}
                open={showOwners}
                onClose={() => setShowOwners(!showOwners)}
            />
        </>
    )
};

export default Owner;