import React from "react";
import {Dialog, TableHead, TableRow, TableCell, TableBody, Table, Typography} from "@material-ui/core";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const OwnersHistoryModal = ({open, onClose, certificate}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <div className={classes.container}>
                <Typography component="h6" variant="h6" gutterBottom>
                    Owners history
                </Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Previous owners</TableCell>
                            <TableCell align="center">Product type</TableCell>
                            <TableCell align="center">Owner from - to</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {certificate.transfers.map((transfer, index) => (
                            <TableRow key={index}>
                                <TableCell>{transfer.sender.name}</TableCell>
                                <TableCell align="center">
                                    {certificate.properties.productType}
                                </TableCell>
                                <TableCell align="center">
                                    {moment(certificate.date_created).format("MMM Do YY")} / {moment(transfer.date_created).format("MMM Do YY")}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Dialog>
    );
};

export default OwnersHistoryModal;
