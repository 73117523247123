import axios from "axios";
import {api} from "config";

export const generatePdf = async (html) => {
    const response = await axios({
        method: "post",
        url: `${api}/download`,
        data: {html},
        responseType: 'blob'
    });
    return response;
};