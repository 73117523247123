import React from 'react';
import {isEmpty, pick} from "ramda";
import {observer} from 'mobx-react-lite';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import PageLoader from "@core/components/PageLoader";
import {useStores} from "@core/hooks/useStores";
import notifications from '@core/stores/notifications';
import UserForm from "./components/UserForm";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Account = () => {
    const classes = useStyles();

    const {UsersStore} = useStores();

    if (isEmpty(UsersStore.user)) return <PageLoader />;

    const user = pick(['username', 'firstname', 'lastname', 'email'], UsersStore.user);
    return (
        <>
            <Typography
                component="h1"
                variant="h5"
                className={classes.header}
            >
                Account
            </Typography>
            <UserForm
                user={user}
                onSubmit={async (values) => {
                    await UsersStore.changeUser(values);
                    notifications.success();
                }}
                changePassword={async (values) => {
                    await UsersStore.changePassword(values);
                    notifications.success();
                }}
            />
        </>
    );
};

export default observer(Account);
