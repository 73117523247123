export default () => ({
  table: {
    marginBottom: 30,
    "& th": {
      padding: "15px 20px",
      fontSize: 20,
      fontWeight: 700
    },
    "& td": {
      padding: "10px 20px",
      fontSize: 16,
      fontWeight: 400,
    },
    "& td, th": {
      border: "1px solid #e7e7e7"
    },
  },
  file: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",

    "& img": {
      height: 50,
    },
    "& p": {
      paddingLeft: 15,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#575757",
      maxWidth: 200,
      textDecoration: "underline"
    },
    "&:hover": {
      color: "rgb(2, 119, 189)",
      textDecoration: "underline",
    }
  },
  img: {
    maxWidth: 200,
    maxHeight: 200,
    width: "100%",
    height: "100%",
    transition: "box-shadow 0.15s",
    "&:hover": {
      webkitBoxShadow: "0px 0px 15px 0px  rgba(0,0,0,0.75)",
      mozBoxShadow: "0px 0px 15px 0px  rgba(0,0,0,0.75)",
      boxShadow: "0px 0px 15px 0px  rgba(0,0,0,0.75)"
    }
  }
});
