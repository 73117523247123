export default () => ({
    menuItem: {
        height: 62,
        "& span": {
            fontSize: 14,
            whiteSpace: "nowrap",
        },
        "& svg": {
            width: 22,
            height: 22,
            fill: "#9e9e9e",
        },
        "&:hover": {
            "& svg": {
                fill: "white",
            },
            color: "white",
            backgroundColor: "#222222",
        },
    },
    activeMenuItem: {
        "& svg": {
            fill: "white",
        },
        color: "white",
        backgroundColor: "#171717",
        borderBottom: "1px solid #454545",
        borderTop: "1px solid #454545",
        "& + a": {
            borderTop: 0,
        }
    },
});