import React, {Component} from "react";
import {Grid, withStyles, Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";

import styles from "./styles";

const FieldPartTable = ({classes, elements, start, end}) => {

  if(!elements || elements.length < 1) {
    return null;
  }

  return (
    <Table className={classes.fieldsTable}>
      <TableHead>
        <TableRow>
          <TableCell size='small'>Field</TableCell>
          <TableCell>Ferrite %</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          elements.slice(start, end).map((element, index) => (
            <TableRow key={index}>
              <TableCell>{element.field}</TableCell>
              <TableCell><b>{element.spacing}</b></TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

class FerriteContent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      test: {properties},
      classes
    } = this.props;

    const fieldsPerColumn = Math.ceil(Number(properties.fields_number)/3);

    return (
      <>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={4}>
            <div className={classes.name}>Client</div>
            <span className={classes.value}>{properties.client || "-"}</span>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.name}>Lab</div>
            <span className={classes.value}>{properties.lab || "-"}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <div className={classes.name}>Test Norms</div>
            <span className={classes.value}>{properties.norm}</span>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.name}>Specimen</div>
            <span className={classes.value}>{properties.specimen || "-"}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <div className={classes.name}>Acceptance criteria</div>
            <span className={classes.value}>{properties.acceptance}</span>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.name}>Etching</div>
            <span className={classes.value}>{properties.etching}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <div className={classes.name}>Sampling</div>
            <span className={classes.value}>{properties.sampling}</span>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.name}>Magnification</div>
            <span className={classes.value}>{properties.magnification}</span>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={12} className={classes.gridFields}>
            <FieldPartTable
              classes={classes}
              elements={properties.elements}
              start={0}
              end={fieldsPerColumn}
            />
            <FieldPartTable
              classes={classes}
              elements={properties.elements}
              start={fieldsPerColumn}
              end={fieldsPerColumn*2}
            />
            <FieldPartTable
              classes={classes}
              elements={properties.elements}
              start={fieldsPerColumn*2}
              end={fieldsPerColumn*3}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom}>
          <Grid item xs={12}>
            <Table className={classes.tableInput}>
              <TableHead>
                <TableRow>
                  <TableCell>Mean austenite (µm)</TableCell>
                  <TableCell>Standard deviation</TableCell>
                  <TableCell>CI 95%</TableCell>
                  <TableCell>Relative accuracy %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><span className={classes[properties.meanAustenite_color]}><b>{properties.meanAustenite}</b></span></TableCell>
                  <TableCell><b>{properties.deviation}</b></TableCell>
                  <TableCell><b>{properties.ci}</b></TableCell>
                  <TableCell><b>{properties.accuracy}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </>
    );
  }

}

export default withStyles(styles)(FerriteContent);
