export default () => ({
  section: {
    color: "#575757",
    "& p": {
      fontSize: 16,
      marginBottom: 5,
    },
    "& h6": {
      fontSize: 16,
    }
  },
  table: {
    "& th, td": {
      color: "#616161",
      textAlign: "center",
      fontSize: 20,
      fontWeight: 500
    }
  },
  imageContainer: {
    position: "relative",
    height: 120,
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  img: {
    height: "100%",
    maxWidth: 180,
    "&:hover": {
      cursor: "pointer",
    },
    "&:hover + div": {
    }
  },
  bigImg: {
    maxWidth: "100%",
    maxHeight: 500
  },
  imgOverlay: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff00",
    transition: "background-color 0.15s linear",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ffffff73",
    },
    "&:hover > svg": {
      display: "block"
    },
  },
  imagesGrid: {
    marginBottom: 30
  },
  zoomIn: {
    display: "none"
  },
  zoomedImage: {
    width: "100%",
    maxHeight: 400,
    objectFit: "contain",
  },
  inner: {
    marginTop: 15,
  },
  modalText: {
    color: "#616161"
  },
  modalNaming: {
    fontWeight: 500,
  },
  centered: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "block",
    textTransform: "uppercase",
    marginBottom: 10
  },
  modalTable: {
    border: "1px solid #d4d1d1",
    marginRight: 0
  },
  row: {
    borderBottom: "1px solid #d4d1d1"
  },
  cell: {
    borderLeft: "1px solid #d4d1d1"
  },
  withPadding: {
    padding: "20px 20px 20px 35px"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    padding: 15,
  }
});