import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const HydrostaticTest = ({test}) => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={2} alignContent="space-between" className={classes.infoRow}>
                <Grid item xs={3}>
                    <h4>Material:</h4>
                    <span className={classes.values}>{test.properties.material}</span>
                </Grid>
                <Grid item xs={3}>
                    <h4>Testing Fluid:</h4>
                    <span className={classes.values}>{test.properties.testingFluid}</span>
                </Grid>
                <Grid item xs={3}>
                    <h4>Pressure:</h4>
                    <span className={classes.values}>{test.properties.pressure}</span>
                </Grid>

                <Grid item xs={3}>
                    <h4>Minimal Holding Time:</h4>
                    <span className={classes.values}>{`${test.properties.minTime}`}</span>
                </Grid>
                <Grid item xs={6}>
                    <h4>Dimensions:</h4>
                    <span
                        className={classes.values}>{`${test.properties.diameter} X ${test.properties.weight} X ${test.properties.size} MM`}</span>
                </Grid>
            </Grid>
        </>
    );
}

export default HydrostaticTest;
