export default () => ({
  marginBottom: {
    marginBottom: "32px"
  },
  
  table: {
    "& td:first-of-type": {
      width: "80%"
    }
  },
  
  values: {
    fontWeight: 700,
    color: "#616161"
  },

  name: {
    fontWeight: 400,
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: 700,
  }
});

