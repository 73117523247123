import React from 'react';
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ListMenuItem from "../ListMenuItem";
import styles from "./styles";

const useStyles = makeStyles(styles);

const MenuItem = ({item, open}) => {
    const classes = useStyles(styles);

    const listItem = (
        <ListMenuItem
            icon={item.icon}
            itemName={item.title}
            link={item.link}
        />
    );
    if(open) return listItem;
    return (
        <Tooltip
            className={classes.tooltip}
            title={item.title}
        >
            <div>{listItem}</div>
        </Tooltip>
    );
};

export default MenuItem;
