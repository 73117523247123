import {makeObservable, observable, action} from "mobx"
import axios from "axios";
import {api} from "config";

class FeedbackStore {
    lineItemFeedback = {};

    constructor() {
        makeObservable(this, {
            lineItemFeedback: observable,
            sendFeedback: action,
            getFeedback: action,
        })
    }


    async sendFeedback(orderId, lineItem, feedback, message) {
        const order = await axios({
            method: "put",
            url: `${api}/orders/feedback/${orderId}/${lineItem}/${feedback}`,
            data: {message},
        });

        this.lineItemFeedback[`${orderId}.${lineItem}`] = order.lineItems[lineItem].feedback;
    }

    async getFeedback(orderId, lineItem) {
        const lineItemFeedback = await axios({
            method: "get",
            url: `${api}/orders/feedback/${orderId}/${lineItem}`,
        });

        this.lineItemFeedback[`${orderId}.${lineItem}`] = lineItemFeedback;
    }
}

export default new FeedbackStore();