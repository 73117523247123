import React from "react";
import {CircularProgress, Grid, InputAdornment, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import NavigationBar from "../NavigationBar";
import styles from "./styles";

const useStyles = makeStyles(styles);

const FiltrationPanel = ({isLoaded, isSearching, setOffset, setSearch}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item>
                <NavigationBar />
            </Grid>
            <Grid item className={classes.inputContainer}>
                <TextField
                    classes={{
                        root: classes.searchField,
                    }}
                    helperText={isLoaded && !isSearching ? "No orders found" : null}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {isSearching && <CircularProgress size={20}/>}
                            </InputAdornment>),
                        classes: {
                            input: classes.input,
                        }
                    }}
                    placeholder={"Search orders e.g. \"Pipe\""}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setOffset(0);
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default FiltrationPanel;