import React, {useState} from "react";
import {Formik} from "formik";
import {Grid, Dialog, DialogContent, Button, InputAdornment, DialogTitle, IconButton} from "@material-ui/core";
import * as yup from "yup";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Input from "@core/components/TextField";

const initialValues = {
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: ""
};

const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("Old password is required!"),
    newPassword: yup.string()
        .required("New password is required!")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){2})(?=.*[/\\^:@$!%*?&)(+#.])[A-Za-z\d/\\^:@$!%*?&)(+#.]{8,}$/,
            "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, two numbers and one special character"
        ),
    passwordConfirmation: yup.string()
        .required("Please confirm your password")
        .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
});

const PasswordField = ({label, name}) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Input
            variant="outlined"
            fullWidth
            label={label}
            name={name}
            required
            type={showPassword ? "text" : "password"}
            autoComplete='off'
            InputProps={{
                endAdornment: (
                    <InputAdornment>
                        <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};

const PasswordChange = ({open, onClose, changePassword}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Password changing</DialogTitle>
            <DialogContent>
                <Formik
                    enableReinitialize
                    onSubmit={async (values) => {
                        await changePassword(values);
                        onClose();
                    }}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(props) => {
                        const {isValid, handleSubmit} = props;

                        return (
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <PasswordField
                                        label="Old password"
                                        name="oldPassword"
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        label="New password"
                                        name="newPassword"
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        label="Confirm new password"
                                        name="passwordConfirmation"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={!isValid}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default PasswordChange;
