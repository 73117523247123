import React from 'react';
import TestPdf from "@core/components/TestPdf";
import {getTestExportId} from "@core/api/exportService";
import {downloadJSON, downloadPdf, downloadXml} from "@core/helpers";
import ActionsCell from "../ActionsCell";

const TestActions = ({test}) => {

    const getPdf = async (exportId) => {
        const Test = (
            <TestPdf
                test={test}
                exportId={exportId}
            />
        );
        await downloadPdf(`${test._id}.pdf`, Test);
    };

    const getXml = (exportId) => {
        const data = {test: {export_id: exportId, ...test}};
        downloadXml(`${test._id}.xml`, data);
    };

    const getJSON = (exportId) => {
        const data = {export_id: exportId, ...test};
        downloadJSON(`${test._id}.json`, data);
    };

    return (
        <ActionsCell
            getExportId={() => getTestExportId(test._id)}
            viewHref={`/index/test/${test._id}`}
            downloadPdf={getPdf}
            downloadXml={getXml}
            downloadJSON={getJSON}
        />
    );
};

export default TestActions;
