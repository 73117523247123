import React, {useState} from 'react';
import {Button, Grid} from "@material-ui/core";
import Input from "@core/components/TextField";
import {Formik} from "formik";
import * as yup from "yup";
import PasswordChange from "../PasswordChange";

const validationSchema = yup.object().shape({
    username: yup.string().required("The field is required!"),
    firstname: yup.string().required("The field is required!"),
    lastname: yup.string().required("The field is required!"),
    email: yup.string().email("Email has to be of type name@email.com"),
});


const UserForm = ({user, onSubmit, changePassword}) => {
    const [showPasswordForm, setShowPasswordForm] = useState(false);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={user}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={3}>
                            <Input
                                fullWidth
                                required
                                name="username"
                                label="Username"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                fullWidth
                                required
                                name="firstname"
                                label="First name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                fullWidth
                                required
                                name="lastname"
                                label="Last name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                autoComplete='off'
                                fullWidth
                                name="email"
                                label="Email"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} container spacing={2}>
                            <Grid item>
                                <Button
                                    disabled={!props.isValid}
                                    variant="contained"
                                    color="primary"
                                    onClick={props.handleSubmit}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowPasswordForm(true)}
                                >
                                    Change password
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Formik>
            <PasswordChange
                changePassword={changePassword}
                open={showPasswordForm}
                onClose={() => setShowPasswordForm(false)}
            />
        </>
    );
}

export default UserForm;
