export default (theme) => ({
    appBar: {
        height: 60,
        "& div": {
            height: "100%"
        },
        boxShadow: "none",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    logo: {
        display: "flex",
        alignItems: "center",
        "& img": {
            width: 120,
            filter: "invert(1)",
        },
        "& span": {
            fontFamily: "Kelly Slab, cursive",
            fontSize: 17,
            marginRight: 8,
        },
        marginLeft: 20,
    },
    companyLogo: {
        padding: 10,
        overflow: "hidden",
        height: "100%",
        width: "100%",
        textAlign: "center",
        minWidth: 170,
        backgroundColor: "#ffffff",
        "& img": {
            width: "auto",
            height: "100%",
        }
    },
    toolbar: {
        "& a": {
            height: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderLeft: "1px solid white",
            padding: "0 20px",
        },
        "& img": {
            filter: "invert(1)",
            height: 24,
            width: 20,
        },
    },
});