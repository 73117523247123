export default () => ({
    actionsCell: {
        display: "flex",
        justifyContent: "space-around",
        "& button": {
            width: "50%",
        },
        "& a": {
            width: "50%",
        }
    },
});