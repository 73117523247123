import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {observer} from 'mobx-react-lite';
import {useStores} from "@core/hooks/useStores";

const Notifier = () => {
    const [displayed, setDisplayed] = useState([]);

    const {enqueueSnackbar} = useSnackbar();
    const {NotificationsStore} = useStores();

    const storeDisplayed = (id) => {
        setDisplayed([...displayed, id]);
    };

    const {notifications = []} = NotificationsStore;

    useEffect(() => {

        notifications.forEach((notification) => {
            if (displayed.includes(notification.key)) return;
            enqueueSnackbar(notification.message, {variant: notification.severity});
            storeDisplayed(notification.key);
            NotificationsStore.remove(notification.key);
        });

    }, [notifications.length]);

    return null;
}

export default observer(Notifier);
