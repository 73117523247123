import React from "react";
import {Grid, Table, TableBody, TableRow, TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const MagneticParticleTest = ({test}) => {
    const properties = test.properties;
    const classes = useStyles();

    return (
        <>
            <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
                <Grid item xs={6}>
                    <div className={classes.name}>Test Norms</div>
                    <span className={classes.value}>{properties.norm.join(", ")}</span>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.name}>Examination condition</div>
                    <span className={classes.value}>{properties.condition.join(", ")}</span>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.name}>Acceptance criteria</div>
                    <span className={classes.value}>{properties.acceptance.join(", ")}</span>
                </Grid>
            </Grid>
            <Grid container alignContent="space-between" className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Material</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Magnetic particles</TableCell>
                                <TableCell>{properties.material.particles}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>{properties.material.type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Powder solution in</TableCell>
                                <TableCell>{properties.material.solution}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Powder concentration</TableCell>
                                <TableCell>{properties.material.concentration}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Equipment</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Bench</TableCell>
                                <TableCell>{properties.equipment.bench}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Magnetizing technique</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Magnetizing current</TableCell>
                                <TableCell>{properties.technique.current}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Current type</TableCell>
                                <TableCell>{properties.technique.current_type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Demagnetization</TableCell>
                                <TableCell>{properties.technique.demagnetization}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container className={classes.marginBottom}>
                <Grid item xs={12}>
                    <h3>Surface cleaning</h3>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Before test</TableCell>
                                <TableCell>{properties.cleaning.before}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>After test</TableCell>
                                <TableCell>{properties.cleaning.after}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    );

}

export default MagneticParticleTest;
