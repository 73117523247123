import React from "react";
import {Grid, Table, TableBody, TableHead, TableRow, TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {platform} from "config";

const useStyles = makeStyles(styles);

const HeatTreatment = ({test}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Temp (C<sup>o</sup>)</TableCell>
                <TableCell>Holding time (s)</TableCell>
                <TableCell>Cooling medium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{test.properties.type}</TableCell>
                <TableCell>{test.properties.temp}</TableCell>
                <TableCell>{test.properties.holdingTime}</TableCell>
                <TableCell>{test.properties.coolingMedium}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {test.properties.images.map((image, index) =>
          <Grid item xs={12} key={index}>
            <img src={`${platform}${image.preview_path}`} alt="img" className={classes.img}/>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default HeatTreatment;
