export default () => ({
  values: {
    fontFamily: "Gotham Bold, sans-serif",
    fontWeight: "400",
    color: "#616161"
  },
  infoRow: {
    "& h4": {
      fontWeight: 400,
      color: "#616161",
      marginBottom: 5,
    }
  },
  alignBottom: {
    marginLeft: "auto"
  }
});

