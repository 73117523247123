import React, {useEffect, useRef} from 'react';
import {useStores} from "@core/hooks/useStores";
import {observer} from "mobx-react-lite";
import Certificate from "@core/components/Certificate";
import Test from '@core/components/Test';
import PageLoader from "@core/components/PageLoader";
import {sortTestsByType} from '@core/helpers';

export default observer(({match}) => {
    const testsRef = useRef({});

    const {CertificatesStore} = useStores();

    useEffect(() => {
        CertificatesStore.getCertificate(match.params.id);
    }, []);

    const certificate = CertificatesStore.certificatesById[match.params.id];
    if(!certificate) return <PageLoader />;

    const sortedTests = sortTestsByType(certificate.tests);

    return (
        <>
            <Certificate
                certificate={certificate}
                executeScroll={(testId) => {
                    testsRef.current[testId].scrollIntoView({behavior: "smooth", block: "start"});
                }}
                tests={sortedTests}
            />
            {sortedTests.map((test, index) => (
                <div ref={(element) => testsRef.current[test._id] = element}>
                    <Test
                        isLast={index === sortedTests.length - 1}
                        key={test._id}
                        test={test}
                        expandable
                    />
                </div>
            ))}
        </>
    );
});
