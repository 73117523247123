import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {TYPES, TEST_NAME_BY_TYPE} from "@core/constants/test";
import Timeline from "@core/components/Timeline";
import BendTest from "./Bend";
import CertificateStatementsTest from "./CertificateStatements";
import CorrosionTest from "./Corrosion";
import HeatTreatmentTest from "./HeatTreatment";
import HydrostaticTest from "./Hydrostatic";
import LiquidePenetrantTest from "./LiquidePenetrant";
import NotesTest from "./Notes";
import OtherTest from "./Other";
import TreatmentTest from "./Treatment";
import UltrasonicTest from "./Ultrasonic";
import SupplementTest from "./Supplement";
import SplitTest from './Split';
import ChemicalCompositionTest from './ChemicalComposition';
import ImpactTest from './Impact';
import TensileTest from "./TensileTest";
import HardnessTest from "./Hardness";
import RadioGraphicTest from "./RadioGraphic";
import MagneticParticleTest from "./MagneticParticle";
import AusteniteSpacingTest from "./AusteniteSpacing";
import FerriteTest from "./Ferrite";
import MicrographicTest from './Micrographic';
import SupplierCertificates from "./SupplierCertificates";
import styles from './styles';

const useStyles = makeStyles(styles);

const TEST_CONFIG_BY_TYPE = {
    [TYPES.CHEMICAL]: {
        component: ChemicalCompositionTest,
        color: "#d02d52",
        icon: "/images/cert-icons/chem-comp.svg",
        title: TEST_NAME_BY_TYPE[TYPES.CHEMICAL],
    },
    [TYPES.IMPACT]: {
        component: ImpactTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/impact-test.svg",
        title: TEST_NAME_BY_TYPE[TYPES.IMPACT],
    },
    [TYPES.TENSILE]: {
        component: TensileTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/tensile-test.svg",
        title: TEST_NAME_BY_TYPE[TYPES.TENSILE],
    },
    [TYPES.HARDNESS]: {
        component: HardnessTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/hardness-test.svg",
        title: TEST_NAME_BY_TYPE[TYPES.HARDNESS],
    },
    [TYPES.HYDROSTATIC]: {
        component: HydrostaticTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/hydrostatic_test.png",
        title: TEST_NAME_BY_TYPE[TYPES.HYDROSTATIC],
    },
    [TYPES.CORROSION]: {
        component: CorrosionTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/corrosion-test.svg",
        title: TEST_NAME_BY_TYPE[TYPES.CORROSION],
    },
    [TYPES.RADIOGRAPHIC]: {
        component: RadioGraphicTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/radio-graphic.svg",
        title: TEST_NAME_BY_TYPE[TYPES.RADIOGRAPHIC],
    },
    [TYPES.LIQUID]: {
        component: LiquidePenetrantTest,
        color:"#9c27b0",
        icon: "/images/cert-icons/liquid_penetrant.png",
        title: TEST_NAME_BY_TYPE[TYPES.LIQUID],
    },
    [TYPES.BEND]: {
        component: BendTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/bending.svg",
        title: TEST_NAME_BY_TYPE[TYPES.BEND],
    },
    [TYPES.NOTES]: {
        component: NotesTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/notes_test.png",
        title: TEST_NAME_BY_TYPE[TYPES.NOTES],
    },
    [TYPES.TREATMENT]: {
        component: TreatmentTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/treatment_test.gif",
        title: TEST_NAME_BY_TYPE[TYPES.TREATMENT],
    },
    [TYPES.OTHER]: {
        component: OtherTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/other_test.png",
        title:  TEST_NAME_BY_TYPE[TYPES.OTHER],
    },
    [TYPES.ULTRASONIC]: {
        component: UltrasonicTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/ultrasonic_test.gif",
        title: TEST_NAME_BY_TYPE[TYPES.ULTRASONIC],
    },
    [TYPES.MAGNETIC]: {
        component: MagneticParticleTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/magnetic_particle.png",
        title: TEST_NAME_BY_TYPE[TYPES.MAGNETIC],
    },
    [TYPES.AUSTENITE]: {
        component: AusteniteSpacingTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/ferrite.png",
        title: TEST_NAME_BY_TYPE[TYPES.AUSTENITE],
    },
    [TYPES.FERRITE]: {
        component: FerriteTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/ferrite.png",
        title: TEST_NAME_BY_TYPE[TYPES.FERRITE],
    },
    [TYPES.MICROGRAPHIC]: {
        component: MicrographicTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/micrographic.png",
        title: TEST_NAME_BY_TYPE[TYPES.MICROGRAPHIC],
    },
    [TYPES.HEATTREATMENT]: {
        component: HeatTreatmentTest,
        color: "#9c27b0",
        icon: "/images/cert-icons/heat_treatment.png",
        title: TEST_NAME_BY_TYPE[TYPES.HEATTREATMENT]
    },
    [TYPES.SPLIT]: {
        component: SplitTest,
        color: "#0277BD",
        icon: "/images/cert-icons/split.png",
        title: TEST_NAME_BY_TYPE[TYPES.SPLIT],
    },
    [TYPES.SUPPLEMENT]: {
        component: SupplementTest,
        color: "#0277BD",
        icon: "/images/cert-icons/supplement_test.svg",
        title: TEST_NAME_BY_TYPE[TYPES.SUPPLEMENT],
    },
    [TYPES.STATEMENT]: {
        component: CertificateStatementsTest,
        color: "#0277BD",
        icon: "/images/cert-icons/statement.svg",
        title: TEST_NAME_BY_TYPE[TYPES.STATEMENT],
    },
    [TYPES.SUPPLIER]: {
        component: SupplierCertificates,
        color: "#0277BD",
        icon: "/images/cert-icons/supplement_test.svg",
        title: TEST_NAME_BY_TYPE[TYPES.SUPPLIER],
    },
};

const Test = ({test, expandable, isLast}) => {
    const testConfig = TEST_CONFIG_BY_TYPE[test.type] || {};
    const Component = testConfig.component;

    const classes = useStyles();

    if(!Component) return null;
    return (
        <Grid container justify="space-between" className={classes.test}>
            <Grid item xs={9} container>
                <Grid item xs={2} style={{backgroundColor: testConfig.color}} className={classes.testMarker}>
                    <img
                        src={testConfig.icon}
                        alt={testConfig.title}
                    />
                    <p>{testConfig.title}</p>
                </Grid>
                <Grid item xs={10} className={classes.testDescription}>
                    <Typography variant="h4" component="h1">
                        {testConfig.title}
                    </Typography>
                    <Component test={test} />
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <div className={classes.timeline}>
                    <Timeline
                        expandable={expandable}
                        transactions={test.transactions}
                    />
                    {!isLast && expandable && (
                        <div className={classes.relation}>
                            <div className={classes.circle} />
                            <div className={classes.line} />
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default Test;