import { makeObservable, observable, action } from "mobx"
import axios from "axios";
import {api} from "config";

class UsersStore {
    user = {}

    constructor() {
        makeObservable(this, {
            user: observable,
            getUser: action
        })
    }

    async getUser() {
        const response = await axios({
            method: "get",
            url: `${api}/users/me`,
        });

        this.user = response;
    }

    async changeUser(changes) {
        const response = await axios({
            method: "put",
            url: `${api}/users`,
            data: changes,
        });

        this.user = response;
    }

    async changePassword({oldPassword, newPassword}) {
       await axios({
            method: "put",
            url: `${api}/users/password`,
            data: {oldPassword, newPassword},
        });
    }
}
export default new UsersStore();