import React, {useEffect} from "react";
import {logout} from "@core/api/loginService";

const Logout = ({history}) => {
    const makeLogout = async () => {
        await logout();

        localStorage.removeItem("expiresIn");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        history.push('/login');
    }

    useEffect(() => {
      makeLogout();
    },[]);

    return null;
};

export default Logout;
