import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import classNames from 'classnames';
import styles from "./styles";

const useStyles = makeStyles(styles);

const ListMenuItem = ({active, icon, itemName, link, onClick, className}) => {
    const classes = useStyles(styles);
    return (
        <ListItem
            button
            className={classNames(classes.menuItem, className)}
            {...link ? {
                isActive: (match) => {
                    return match || active;
                },
                activeClassName: classes.activeMenuItem,
                component: NavLink,
                to: link,
            } : {}}
            onClick={onClick}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={itemName} />
        </ListItem>
    );
};

export default ListMenuItem;
