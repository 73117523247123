import React from 'react';
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DownloadPopover from "../DownloadPopover";
import styles from './styles';

const useStyles = makeStyles(styles);

const ActionsCell = ({viewHref, ...props}) => {
    const classes = useStyles();
    return (
        <div className={classes.actionsCell}>
            <Button
                color="primary"
                href={viewHref}
            >
                View
            </Button>
            <DownloadPopover {...props} />
        </div>
    );
}

export default ActionsCell;