import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {values} from "ramda";
import {useStores} from "@core/hooks/useStores";
import {TEST_NAME_BY_TYPE} from "@core/constants/test";
import Table from "@core/components/Table";
import PageLoader from "@core/components/PageLoader";
import TestActions from "../TestActions";
import Header from '../TestsHeader';
import moment from "moment";

const DEFAULT_COLUMNS = ['orderNumber', 'lineItem', 'testType', 'item_heat', 'material', 'actions'];

const columns = [{
    dataIndex: 'orderNumber',
    title: "Order number",
}, {
    dataIndex: 'lineItem',
    title: "Report type",
}, {
    dataIndex: 'testType',
    title: "Test type",
    render: test => TEST_NAME_BY_TYPE[test.type],
}, {
    dataIndex: 'item_heat',
    title: "Heat",
}, {
    dataIndex: 'material',
    title: "Material",
    render: test => `${test.norm} / ${test.material_grade || test.tp_UNS}`,
}, {
    dataIndex: 'status',
    title: "Status",
}, {
    dataIndex: "date_created",
    title: "Date",
    render: test => moment(test.date_created).format("MMM Do YYYY"),
}, {
    dataIndex: 'actions',
    title: "Actions",
    render: test => (
        <TestActions
            test={test}
        />
    ),
}];

const Tests = () => {
    const {TestsStore} = useStores();

    useEffect(() => {
        TestsStore.getList();
    }, [])

    const {testsById, isLoaded} = TestsStore;

    const tests = values(testsById);

    if (!isLoaded) return <PageLoader />;

    return (
        <Table
            header={<Header/>}
            items={tests}
            columns={columns}
            allowRowSelection
            defaultColumns={JSON.parse(localStorage.getItem("tests")) || DEFAULT_COLUMNS}
            allowColumnSelection
            saveSelectedColumns={(selectedColumns) => {
                localStorage.setItem("tests", JSON.stringify(selectedColumns));
            }}
            noFoundText="No tests found"
        />
    );
};

export default observer(Tests);