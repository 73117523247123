export default () => ({
    table: {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        "& th": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
        },
        "& td": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
        }
    },
    container: {
        padding: 20,
    }
});