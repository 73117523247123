import React from 'react';
import {head, toUpper} from "ramda";
import {Avatar} from '@material-ui/core';

const UserAvatar = ({src, firstName, lastName, className}) => {
    return (
        <Avatar className={className} src={src}>
            {`${toUpper(head(firstName))}${toUpper(head(lastName))}`}
        </Avatar>
    );
};

export default UserAvatar;