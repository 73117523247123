import React from 'react';
import {SnackbarProvider} from "notistack";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {Slide} from "@material-ui/core";
import Notifier from "@core/components/Notifier";

const NotificationsProvider = ({children}) => {
    const notistackRef = React.createRef();
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            ref={notistackRef}
            action={(key) => (
                <HighlightOffIcon onClick={() => notistackRef.current.closeSnackbar(key)} />
            )}
            TransitionComponent={Slide}
        >
            <Notifier />
            {children}
        </SnackbarProvider>
    )
};

export default NotificationsProvider;
