import React from "react";
import {isEmpty} from "ramda";
import {makeStyles} from "@material-ui/core/styles";
import {AppBar, Toolbar, Grid} from "@material-ui/core";
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {observer} from "mobx-react-lite";
import {useStores} from "@core/hooks/useStores";
import {platform} from "config";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Header = ({open}) => {
    const classes = useStyles();

    const {UsersStore} = useStores();

    if(isEmpty(UsersStore.user)) return null;

    return (
        <AppBar
            position="static"
            className={classNames(
                classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Grid container justify="space-between">
                <Grid item xs={8} container>
                    {UsersStore.user.createdBy && UsersStore.user.createdBy.logo && (
                        <Grid item>
                            <div className={classes.companyLogo}>
                                <img
                                    src={`${platform}${UsersStore.user.createdBy.logo}`}
                                    alt="company logo"
                                />
                            </div>
                        </Grid>)}
                    <Grid item xs={4} className={classes.logo}>
                        <span>Powered by</span>
                        <img src="/images/logo-symbol.svg" alt="logo" />
                    </Grid>
                </Grid>
                <Grid item xs={4} container justify="flex-end">
                    <Toolbar variant="dense" align="right" disableGutters className={classes.toolbar}>
                        <Link to="/account">
                            <img src="/images/profile.svg" alt="profile" />
                        </Link>
                        <Link to="/logout">
                            <img src="/images/logout.svg" alt="logout" />
                        </Link>
                    </Toolbar>
                </Grid>
            </Grid>
        </AppBar>
    );
};

export default observer(Header);