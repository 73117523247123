import React from "react";
import {
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const OtherTest = ({test}) => {
    const classes = useStyles();
    return (
        <Grid container alignContent="space-between">
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell size='small'>Test</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Result</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {test.properties.elements.map((element, index) => (
                            <TableRow key={`prop${index}`}>
                                <TableCell>{element.test}</TableCell>
                                <TableCell>{element.description}</TableCell>
                                <TableCell>
                                            <span
                                                className={element.result === "Unsatisfactory" ? classes.redValidate : classes.greenValidate}>{element.result}</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );

}

export default OtherTest;
