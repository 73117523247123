export default (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  tableContainer: {
    padding: "0"
  },
  table: {
    width: "100%",
    fontSize: "16px",
    paddingBottom: "15px"
  },
  tableCell: {
    fontWeight: "600 !important"
  },
  tableCellRed: {
    color: "#f44336 !important",
    fontWeight: "600 !important"
  },
  tableCellGreen: {
    color: "#4caf50 !important",
    fontWeight: "600 !important"
  },
  greenValidate: {
    color: "#4caf50",
    fontWeight: "600",
    paddingRight: "40px",
    marginTop: "-40px"
  },
  redValidate: {
    color: "#f44336",
    fontWeight: "600",
    paddingRight: "0",
    paddingTop: "40px",
    marginTop: "-40px",
  },
  subTitle: {
    marginBottom: "15px",
    marginTop: "15px",
    color: "#616161",
    fontWeight: "500"
  },
  uploadResults: {
    marginTop: "25px"
  },
  impactTable: {
    "& td": {
      textAlign: "center",
    },
    "& th": {
      textAlign: "center",
      "& span": {
        marginTop: 10,
      }
    },
    "& input": {
      padding: "13px 12px",
    }
  },
  cellContainer: {

    display: "flex",
    "& span": {
      flex: 1
    }
  },
  infoRow: {
    marginBottom: 15,
    "& h4": {
      fontWeight: 400,
      color: "#616161",
      marginBottom: 5,
    }
  },
  infoValue: {
    fontWeight: 700,
    display: "block"
  }
});
