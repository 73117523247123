import React, {useEffect} from 'react';
import {useStores} from "@core/hooks/useStores";
import {observer} from "mobx-react-lite";
import PageLoader from "@core/components/PageLoader";
import Test from '@core/components/Test';

export default observer(({match}) => {
    const {TestsStore} = useStores();

    useEffect(() => {
        TestsStore.getTest(match.params.id);
    }, []);

    const test = TestsStore.testsById[match.params.id];

    if(!test) return <PageLoader />;

    return (
        <Test
            test={test}
            isLast
            expandable
        />
    );
});
