import {makeObservable, observable, action} from "mobx";
import axios from "axios";
import {api} from "config";

const addPathToFileObject = (fileObject) => {
    const path = fileObject.name.split("_")[1];
    return {
        path,
        ...fileObject,
    };
}

class UploadsStore {
    lineItemUploads = {}

    constructor() {
        makeObservable(this, {
            lineItemUploads: observable,
            uploadFile: action,
            deleteFile: action,
            getLineItemUploads: action,
        })
    }

    async getLineItemUploads(orderNumber, lineItem) {
        const response = await axios({
            method: "get",
            url: `${api}/upload/${orderNumber}/${lineItem}`,
        });

        const uploads = response.map(upload => ({
            ...upload,
            file: addPathToFileObject(upload.file),
        }));

        this.lineItemUploads[`${orderNumber}.${lineItem}`] = uploads;

    }

    async uploadFile(orderNumber, lineItem, file) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("orderNumber", orderNumber);
        formData.append("lineItem", lineItem);

        const response = await axios({
            method: "post",
            url: `${api}/upload`,
            data: formData,
        });

        if (!response._id) return;

        const lineItemUpload = this.lineItemUploads[`${orderNumber}.${lineItem}`];

        this.lineItemUploads[`${orderNumber}.${lineItem}`] = [
            ...lineItemUpload,
            {
                ...response,
                file: addPathToFileObject(response.file),
            }
        ];
    }

    async deleteFile(orderNumber, lineItem, id) {
        await axios({
            method: "delete",
            url: `${api}/upload/${id}`,
        });
       
        const lineItemUpload = this.lineItemUploads[`${orderNumber}.${lineItem}`];
        this.lineItemUploads[`${orderNumber}.${lineItem}`] = lineItemUpload.filter(upload => upload._id !== id);
    }
}

export default new UploadsStore();