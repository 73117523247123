import React from 'react';
import {makeStyles} from "@material-ui/core";
import Table from "@core/components/Table";
import styles from "./styles";

const useStyles = makeStyles(styles);

const OrdersList = ({orders, getColumns}) => {
    const classes = useStyles();

    return orders.map(order => (
            <div
                key={order._id}
                className={classes.order}
            >
                <Table
                    header={`Order number: ${order.orderNumber}`}
                    items={order.lineItems}
                    columns={getColumns(order)}
                />
            </div>
        )
    );
}

export default OrdersList;