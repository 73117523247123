import React from "react";
import {Grid, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Fade, Button} from "@material-ui/core";
import classNames from "classnames";
import styles from "./styles";
import {platform} from "config";

const ZoomInModal = (props) => {
  const {classes} = props;
  const img = props.data.element.image.preview_path;
  const element = props.data.element;

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"lg"} fullWidth>
      <Fade in={props.open} timeout={300}>
        <>
          <DialogTitle>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={classes.title}>Micrographic examination</span>
                <span>Norm: {props.data.norm}</span>
              </Grid>
              <Grid item xs={6}>
              Related standards: {props.data.acceptanceCriteria}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className={classes.inner}>
              <Grid item xs={6}>
                <img src={`${platform}${img}`} alt="metal under the microscope" className={classes.zoomedImage}/>
              </Grid>
              <Grid item xs={6} className={classNames(classes.modalText, classes.centered)}>
                <Grid container spacing={2} className={classes.modalTable}>
                  <Grid item xs={12} className={classes.row}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} className={classNames(classes.modalNaming, classes.centered)}>
                      Sample identification
                      </Grid>
                      <Grid item xs={4} className={classes.cell}>
                        Client ID: {props.data.client}
                      </Grid>
                      <Grid item xs={4} className={classes.cell}>
                      Lab ID: {props.data.lab}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.row}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} className={classes.modalNaming}>
                      Sampling position
                      </Grid>
                      <Grid xs={8} className={classNames(classes.cell, classes.withPadding)}>
                        {element.location} - {element.position}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.row}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} className={classes.modalNaming}>
                      Etching
                      </Grid>
                      <Grid xs={8} className={classNames(classes.cell, classes.withPadding)}>
                        {props.data.etching}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.row}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} className={classes.modalNaming}>
                      Magnification
                      </Grid>
                      <Grid xs={8} className={classNames(classes.cell, classes.withPadding)}>
                        {props.data.magnification}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} className={classes.modalNaming}>
                      Comments
                      </Grid>
                      <Grid xs={8} className={classNames(classes.cell, classes.withPadding)}>
                        {props.data.resultNotes}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className={classes.footer}>
              <Button variant={"contained"}  size={"medium"} color={"primary"} onClick={props.onClose}>Close</Button>
            </div>
          </DialogActions>
        </>
      </Fade>
    </Dialog>

  );
};

export default withStyles(styles)(ZoomInModal);
