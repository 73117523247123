export default () => ({
    test: {
        fontSize: 16,
        minHeight: 450,
        margin: "10px 0",
    },
    testMarker: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: 18,
        "& p": {
            padding: "30px 15px",
            textAlign: "center",
        },
        "& img": {
            width: 115,
        }
    },
    testDescription: {
        padding: 40,
        backgroundColor: "white",
        "& h1": {
            marginBottom: 50,
        }
    },
    timeline: {
        paddingLeft: 40,
        height: "100%",
    },
    circle: {
        background: "#575757",
        width: 15,
        height: 15,
        borderRadius: "50%",
    },
    relation: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
    },
    line: {
        width: 2,
        background: "#575757",
        height: "100%",
    }
});