import React from "react";
import {Grid, withStyles, Table, TableRow, TableCell, TableBody} from "@material-ui/core";
import styles from "./styles";

const NewFerrite = (props) => {

  const {properties} = props.test;
  const {classes} = props;

  return (
    <>
      <Grid container alignContent="space-between" className={classes.marginBottom}>
        <Grid item xs={4}>
          <div className={classes.name}>Client</div>
          <span className={classes.value}>{properties.client}</span>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.name}>Lab</div>
          <span className={classes.value}>{properties.lab}</span>
        </Grid>
      </Grid>
      <Grid container alignContent="space-between" className={classes.marginBottom}>
        <Grid item xs={4}>
          <div className={classes.name}>Test Norms</div>
          <span className={classes.value}>{properties.norm}</span>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.name}>Specimen</div>
          <span className={classes.value}>{properties.specimen || "-"}</span>
        </Grid>
      </Grid>
      <Grid container alignContent="space-between" className={classes.marginBottom}>
        <Grid item xs={4}>
          <div className={classes.name}>Magnification</div>
          <span className={classes.value}>{properties.magnification}</span>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.name}>Etching</div>
          <span className={classes.value}>{properties.etching}</span>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {properties.positions.map((pos, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Table className={classes.automaticTable}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Position</TableCell>
                    {pos.elements.map((el) =>
                      <TableCell key={el.location} className={classes.value}>{el.location} - {pos.name}</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Acceptance criteria</TableCell>
                    {pos.elements.map((el) =>
                      <TableCell key={el.acceptance} className={classes.value}>{el.acceptance}</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Ferrite %</TableCell>
                    {pos.elements.map((el) =>
                      <TableCell key={el.ferrite} className={classes.value}>{el.ferrite}</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Standard deviation</TableCell>
                    {pos.elements.map((el) =>
                      <TableCell key={el.sd} className={classes.value}>{el.sd}</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>95 % CI</TableCell>
                    {pos.elements.map((el) =>
                      <TableCell key={el.ci} className={classes.value}>{el.ci}</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Rel. Accuracy</TableCell>
                    {pos.elements.map((el) =>
                      <TableCell key={el.accuracy} className={classes.value}>{el.accuracy}</TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default withStyles(styles)(NewFerrite);
