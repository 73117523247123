import React from "react";

const OwnerInfo = ({company}) => {
    if(!company) return null;
    return (
        <>
            <div>{company.name}</div>
            {company.mainLocation && (
                <div>{company.mainLocation.postalCode} {company.mainLocation.city}</div>
            )}
            <div>{company.phone}</div>
            <div>{company.email}</div>
        </>
    );
};
export default OwnerInfo;