const drawerWidth = 260;

export default (theme) => ({
    drawer: {
        backgroundColor: "#1d1d1d",
        border: "none",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 60,
    },
    menuList: {
        height: "100%",
        overflow: "hidden",
        padding: 0,
        position: "relative",
        "& a:last-of-type": {
            borderBottom: "1px solid #454545",
        }
    },
    avatar: {
        position: "absolute",
        top: 10,
        left: 8,
        backgroundColor: theme.palette.primary.main,
    },
    menuToggleButton: {
        borderTop: "1px solid #454545",
        position: "absolute",
        bottom: 0,
        left: 0,
    }
});