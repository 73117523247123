import React, {useState} from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Paper,
    withStyles,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {map, prop, isEmpty, is} from "ramda";
import ColumnsSelect from './ColumnsSelect';
import styles from './styles';
import {SelectedItemContext} from './context';

const useStyles = makeStyles(styles);

const StyledTableRow = withStyles(() => ({
    root: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#edf3fb',
        }
    },
}))(TableRow);

const TableSkeleton = ({items, columns, allowRowSelection, header, allowColumnSelection, defaultColumns, saveSelectedColumns, noFoundText}) => {
    const classes = useStyles();

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(defaultColumns || map(prop('dataIndex'), columns));

    const onCheckboxClick = (item) => {
        const isItemSelected = selectedItems.includes(item._id);
        if (isItemSelected) {
            setSelectedItems(selectedItems.filter(id => id !== item._id));
        } else {
            setSelectedItems(selectedItems.concat(item._id));
        }
    };

    const onAllCheckboxClick = () => {
        if (selectedItems.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(map(prop('_id'), items));
        }
    }

    const selectColumns = (selectedColumns) => {
        setSelectedColumns(selectedColumns);
        saveSelectedColumns(selectedColumns);
    }

    const columnsToShow = columns.filter(({dataIndex}) => selectedColumns.includes(dataIndex));

    return (
        <Paper elevation={0}>
            <div className={classes.header}>
                <SelectedItemContext.Provider value={selectedItems}>
                    <Typography component="h4" variant="h5">
                        {header}
                    </Typography>
                </SelectedItemContext.Provider>
                {allowColumnSelection && (
                    <ColumnsSelect
                        setSelectedColumns={selectColumns}
                        selectedColumns={selectedColumns}
                        columns={columns}
                    />
                )}
            </div>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {allowRowSelection && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={!!selectedItems.length && selectedItems.length < items.length}
                                        checked={selectedItems.length === items.length}
                                        onChange={onAllCheckboxClick}
                                    />
                                </TableCell>
                            )}
                            {columnsToShow.map(({title}) => (
                                <TableCell key={title} align="center">{title}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isEmpty(items) && noFoundText && (
                            <TableRow>
                                <TableCell
                                    className={classes.noFoundText}
                                    colSpan={4}
                                >
                                    {noFoundText}
                                </TableCell>
                            </TableRow>
                        )}
                        {items.map((item, index) => {
                            const isItemSelected = selectedItems.includes(item._id);
                            return (
                                <StyledTableRow
                                    key={item._id}
                                    hover
                                    selected={isItemSelected}
                                >
                                    {allowRowSelection && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                onClick={() => onCheckboxClick(item)}
                                                checked={selectedItems.includes(item._id)}
                                            />
                                        </TableCell>
                                    )}
                                    {columnsToShow.map(({render, dataIndex, width, align}) => (
                                        <TableCell
                                            key={`${item._id}${dataIndex}`}
                                            align={align || "center"}
                                            width={width}
                                        >
                                            {render ? render(item, index) : item[dataIndex]}
                                        </TableCell>
                                    ))}
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default TableSkeleton;
