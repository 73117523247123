import React, {useState} from "react";
import {Grid, Button} from "@material-ui/core";
import RichTextEditor from 'react-rte-17';
import {makeStyles} from "@material-ui/core";
import {useStores} from "@core/hooks/useStores";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Form = ({orderId, lineItem, feedback}) => {
    const {FeedbackStore} = useStores();

    const [showForm, setShowForm] = useState(false);
    const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());

    const classes = useStyles();

    const onSubmit = async () => {
        const htmlContent = editorState.toString('html');

        await FeedbackStore.sendFeedback(orderId, lineItem, feedback, htmlContent);
        setEditorState(RichTextEditor.createEmptyValue());
    };

    if (!showForm) {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => setShowForm(true)}
            >
                Reply
            </Button>
        )
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RichTextEditor
                    toolbarConfig={{
                        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'BLOCK_ALIGNMENT_BUTTONS'],
                        INLINE_STYLE_BUTTONS: [
                            {label: 'Bold', style: 'BOLD'},
                            {label: 'Italic', style: 'ITALIC'},
                            {label: 'Underline', style: 'UNDERLINE'},
                            {label: 'Strikethrough', style: 'STRIKETHROUGH'},
                            {label: 'Monospace', style: 'CODE'},
                        ],
                        BLOCK_TYPE_DROPDOWN: [
                            {label: 'Normal', style: 'unstyled'},
                            {label: 'H1', style: 'header-one'},
                            {label: 'H2', style: 'header-two'},
                            {label: 'H3', style: 'header-three'},
                            {label: 'H4', style: 'header-four'},
                            {label: 'H5', style: 'header-five'},
                            {label: 'H6', style: 'header-six'},
                            {label: 'Blockquote', style: 'blockquote'},
                            {label: 'Code', style: 'code-block'},
                        ],
                        BLOCK_TYPE_BUTTONS: [
                            {label: 'UL', style: 'unordered-list-item'},
                            {label: 'OL', style: 'ordered-list-item'}
                        ],
                        BLOCK_ALIGNMENT_BUTTONS: [
                            {label: 'Left', style: 'ALIGN_LEFT'},
                            {label: 'Center', style: 'ALIGN_CENTER'},
                            {label: 'Right', style: 'ALIGN_RIGHT'},
                            {label: 'Justify', style: 'ALIGN_JUSTIFY'},
                        ]
                    }}
                    className={classes.textEditor}
                    value={editorState}
                    onChange={(editorState) => setEditorState(editorState)}
                    onSave={onSubmit}
                />
            </Grid>
            <Grid item xs={1}>
                <Button
                    disabled={!editorState.getEditorState().getCurrentContent().hasText()}
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    Send
                </Button>
            </Grid>
        </Grid>
    )
};

export default Form;