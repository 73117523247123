export default () => ({
    avatar: {
        width: 55,
        height: 55,
    },
    action: {
        color: "white",
        backgroundColor: "#575757",
        fontWeight: 500,
        padding: "10px 20px",
    },
    content: {
        border: "1px solid #e0e0e0",
        borderBottom: "none",
        backgroundColor: '#ffffff',
        padding: 20,
    },
    signature: {
        cursor: "pointer",
        fontSize: 13,
        textDecoration: "underline",
        overflowWrap: "break-word",
    },
    date: {
        fontSize: 13,
        marginTop: 2,
    },
    companyLogo: {
        display: "flex",
        alignItems: "center",
        textDecoration: "underline",
        fontSize: 13,
        border: "1px solid #e0e0e0",
        backgroundColor: "#ffffff",
        "& img": {
            width: 140,
            height: 55,
        }
    },
});