import React from 'react';
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {observer} from "mobx-react-lite";
import {useStores} from "@core/hooks/useStores";
import Certificates from "./components/Certificates";
import Tests from './components/Tests';
import styles from "./styles";

const useStyles = makeStyles(styles);

const ComponentByUserRole = {
    PRODUCER: Certificates,
    LAB_INTERNAL: Tests,
};

const Dashboard = () => {
    const classes = useStyles();
    const {UsersStore} = useStores();

    if(!UsersStore.user.role) return null;
    const Component = ComponentByUserRole[UsersStore.user.role];

    return (
        <>
            <Typography component="h1" variant="h5" className={classes.welcomeMessage}>
                Welcome to your <span>SteelTrace portal</span>
            </Typography>
            <Component />
        </>
    );
};

export default observer(Dashboard);