import React from "react";
import {Grid, TableCell, Table, TableBody, TableRow, TableHead} from "@material-ui/core";
import {TEST_NAME_BY_TYPE} from "@core/constants/test";
import {platform} from "config";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const configIconsForExt = {
  "zip": "/images/files/ppt/zip",
  "pptx": "/images/files/ppt.svg",
  "ppt": "/images/files/ppt.svg",
  "jpeg": "/images/files/jpg.svg",
  "jpg": "/images/files/jpg.svg",
  "png": "/images/files/png.svg",
  "pdf": "/images/files/pdf.svg",
  "xls": "/images/files/xls.svg",
  "xlsx": "/images/files/xls.svg",
  "doc": "/images/files/doc.svg",
  "docx": "/images/files/doc.svg",
  "txt": "/images/files/txt.svg"
};

const Supplement = (props) => {
  const {test} = props;
  const classes = useStyles();

  const displayFile = (file) => {
    const extension = file.split(".").pop();
    const name = file.split(".")[0].split("_");
    name.shift();

    const src = configIconsForExt.hasOwnProperty(extension) ? configIconsForExt[extension] : "/images/files/file.svg";

    return (
      <a href={`${platform}${file}`} download target={"_blank"} className={classes.file}>
        <img src={src} alt={"download file"} />
        <p>{name}</p>
      </a>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align={"center"}>Creator</TableCell>
                <TableCell align={"center"}>Test type</TableCell>
                <TableCell align={"center"}>Type</TableCell>
                <TableCell align={"center"}>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {test.properties.elements.map((el, index) =>
                <TableRow key={`${test._id}.element.${index}`}>
                  <TableCell align={"center"}>{el.creator}</TableCell>
                  <TableCell align={"center"}>{TEST_NAME_BY_TYPE[el.testType].title}</TableCell>
                  <TableCell align={"center"}>{el.certificateType}</TableCell>
                  <TableCell align={"center"}>{displayFile(el.file.preview_path)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

export default Supplement;
