import React from 'react';
import Test from "@core/components/Test";

const TestPdf = ({test, exportId}) => (
    <div>
        <div style={{textAlign: "right", marginBottom: 20, fontSize: 20}}>
            Export ID: {exportId}
        </div>
        <Test
            test={test}
            expandable={false}
            isLast
        />
    </div>
);

export default TestPdf;