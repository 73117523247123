import React, { Component } from 'react';
import {withStyles} from "@material-ui/core";
import styles from './styles';

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { error: false };
    }

    static getDerivedStateFromError() {
        return { error: true };
    }

    componentDidCatch(error, errorInfo) {
        // log the error to an error reporting service
    }


    render() {
        const {children, classes} = this.props;
        const {error} = this.state;

        if(error) {
            return (
                <div className={classes.container}>
                    <img
                        className={classes.somethingWentWrongImage}
                        src="/images/something-went-wrong.gif"
                        alt="Something went wrong"
                    />
                </div>
            );
        }

        return children;
    }
}

export default withStyles(styles)(ErrorBoundary);