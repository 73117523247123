export default () => ({
    container: {
        padding: "15px 30px",
    },
    qrContainer: {
        display: "flex",
        "& img": {
            marginRight: 5,
        }
    }
});