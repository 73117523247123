export default () => ({
    item: {
        overflowWrap: "anywhere",
    },
    hideRemoveButton: {
        "& .MuiDropzonePreviewList-removeButton": {
            display: "none",
        },
    },
    fileDropzone: {
        "& .MuiDropzonePreviewList-removeButton": {
            top: "-5%",
        },
    },
});