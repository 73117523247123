export default () => ({
    button: {
        marginLeft: "auto",
    },
    checkboxContainer: {
        "& span": {
            fontSize: 14,
        },
        whiteSpace: "nowrap",
        padding: "5px 0 5px 20px",
    },
});