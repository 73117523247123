import axios from "axios";
import {api} from "config";

export const generateAccessToken = async (username, password) => {
    const response = await axios({
        method: "post",
        url: `${api}/users/login`,
        data: {
            username,
            password
        },
    });
    return response;
};

export const generateRefreshToken = async () => {
    const instance = axios.create({
        headers: {
            "X-Token-Jwt": localStorage.getItem("accessToken"),
        }
    });
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await instance({
        method: "post",
        url: `${api}/users/refreshToken`,
        data: {refreshToken}
    });
    return response.data;
};

export const logout = async () => {
    await axios({
        method: "post",
        url: `${api}/users/logout`,
    });
}