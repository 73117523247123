import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import moment from "moment";
import {useStores} from "@core/hooks/useStores";
import {values} from 'ramda';
import PageLoader from "@core/components/PageLoader";
import Table from "@core/components/Table";
import CertificateActions from "../CertificateActions";
import Header from '../CertificatesHeader';

const DEFAULT_COLUMNS = ['orderNumber', 'lineItem', 'certificateType', 'productType', 'heat', 'material', 'actions'];

const columns = [{
    dataIndex: "orderNumber",
    title: "Order number",
}, {
    dataIndex: "lineItem",
    title: "Line item",
    render: certificate => certificate.createdByPortal ? Number(certificate.lineItem) + 1 : certificate.lineItem,
}, {
    dataIndex: "certificateType",
    title: "Certificate type",
}, {
    dataIndex: "productType",
    title: "Product type",
    render: certificate => certificate.properties.productType,
}, {
    dataIndex: "heat",
    title: "Heat",
}, {
    dataIndex: "material",
    title: "Material",
    render: certificate => `${certificate.properties.norm} / ${certificate.material_grade || certificate.tp_UNS}`,
}, {
    dataIndex: "owner",
    title: "Owner",
    render: certificate => certificate.company.name,
}, {
    dataIndex: "lotId",
    title: "Lot ID",
    render: certificate => certificate.properties.lotId,
}, {
    dataIndex: "projectId",
    title: "Project ID",
}, {
    dataIndex: "markingNotes",
    title: "Marking notes",
}, {
    dataIndex: "marking_text",
    title: "Marking text",
}, {
    dataIndex: "date_created",
    title: "Date",
    render: certificate => moment(certificate.date_created).format("MMM Do YYYY"),
}, {
    dataIndex: "actions",
    title: "Actions",
    render: certificate => (
        <CertificateActions
            certificate={certificate}
        />
    ),
}];

const Certificates = () => {
    const {CertificatesStore} = useStores();

    useEffect(() => {
        CertificatesStore.getList();
    }, [])

    const {certificatesById, isLoaded} = CertificatesStore;

    const certificates = values(certificatesById);

    if (!isLoaded) return <PageLoader />;

    return (
        <Table
            header={<Header/>}
            defaultColumns={JSON.parse(localStorage.getItem("certificates")) || DEFAULT_COLUMNS}
            items={certificates}
            columns={columns}
            allowRowSelection
            allowColumnSelection
            saveSelectedColumns={(selectedColumns) => {
                localStorage.setItem("certificates", JSON.stringify(selectedColumns));
            }}
            noFoundText="No certificates found"
        />
    );
};

export default observer(Certificates);