import React from 'react';
import {observer} from 'mobx-react-lite';
import {values} from "ramda";
import TestPdf from "@core/components/TestPdf";
import {downloadJSON, downloadPdf, downloadXml} from "@core/helpers";
import DashboardHeader from "../DashboardHeader";
import {useStores} from "@core/hooks/useStores";

const Header = () => {
    const {TestsStore} = useStores();
    return (
        <DashboardHeader
            items={values(TestsStore.testsById)}
            downloadPdf={async (selectedItems) => {
                const List = selectedItems.map(item => <TestPdf test={item}/>);
                await downloadPdf('tests.pdf', List);
            }}
            downloadXml={(selectedItems) => {
                downloadXml('tests.xml', {tests: selectedItems});
            }}
            downloadJSON={(selectedItems) => {
                downloadJSON('tests.json', selectedItems);
            }}
            name="New tests"
        />
    );
}

export default observer(Header);