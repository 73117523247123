import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import Table from "@core/components/Table";
import {useStores} from "@core/hooks/useStores";
import PageLoader from "@core/components/PageLoader";
import UploadFiles from "../Orders/components/UploadFiles";
import Files from "../Orders/components/Files";
import FeedbackList from "./components/FeedbackList";

const getColumns = (order, lineItemIndex) => [{
    dataIndex: "index",
    title: "LI",
    width: "5%",
    render: () => Number(lineItemIndex) + 1,
}, {
    dataIndex: "certificateType",
    title: "Certificate type",
    width: "10%",
}, {
    dataIndex: "productType",
    title: "Product type",
    width: "10%",
}, {
    dataIndex: "actions",
    title: "Actions",
    width: "15%",
    render: (lineItem, index) => (
        <UploadFiles
            projectId={lineItem.projectId}
            status={lineItem.status}
            certificateId={lineItem.certificate}
            certificateType={lineItem.certificateType}
            productType={lineItem.productType}
            lineItem={index}
            order={order}
        />
    ),
}, {
    dataIndex: "files",
    title: "File(s)",
    width: "30%",
    align: "left",
    render: (_, index) => (
        <Files
            lineItem={index}
            orderNumber={order.orderNumber}
        />
    ),
}, {
    dataIndex: "description",
    width: "20%",
    title: "Description",
}, {
    dataIndex: "status",
    width: "10%",
    title: "Status",
}];


const LineItem = ({match}) => {
    const {OrdersStore, FeedbackStore} = useStores();

    const {id: orderId, lineItem: lineItemIndex} = match.params;

    useEffect(() => {
        if(OrdersStore.ordersById[orderId]) return;

        OrdersStore.getOrder(orderId);
        FeedbackStore.getFeedback(orderId, lineItemIndex);
    }, []);

    const {ordersById, isLoaded} = OrdersStore;

    if (!isLoaded) return <PageLoader />;

    const order = ordersById[orderId] || {};
    const lineItem = order.lineItems ? order.lineItems[lineItemIndex] : null;

    return (
        <>
            <Table
                header={`Order number: ${order.orderNumber}`}
                items={[lineItem]}
                columns={getColumns(order, lineItemIndex)}
            />
            <FeedbackList
                orderId={orderId}
                lineItem={lineItemIndex}
            />
        </>
    );
};

export default observer(LineItem);