import axios from "axios";
import {api} from "config";

export const getCertificateExportId = async (id) => {
    const response = await axios({
        method: "put",
        url: `${api}/certificates/export`,
        params: {certificateId: id}
    });

    const {hash} = response.exports;
    return hash;
};

export const getTestExportId = async (id) => {
    const response = await axios({
        method: "put",
        url: `${api}/tests/export`,
        params: {testId: id}
    });

    const {hash} = response.exports;
    return hash;
};