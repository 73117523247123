import React, {useState} from 'react';
import {Typography, TextField, Button} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import {makeStyles} from '@material-ui/core/styles';

import {generateAccessToken} from '@core/api/loginService';
import styles from './styles';

const useStyles = makeStyles(styles);

const isEnterKey = event => event.key === 'Enter';

const Login = ({history}) => {
    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const login = async () => {
        if (!username || !password) return;

        const response =  await generateAccessToken(username, password);
        if(!response) return;

        const {accessToken, refreshToken, expiresIn} = response;

        localStorage.setItem("expiresIn", expiresIn);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        history.push('/index');
    };

    return (
        <>
            <img src="/images/logo.png" alt="logo"/>
            <Typography component="h1" variant="h5">
                Login
            </Typography>
            <Typography variant="overline">
                Welcome to SteelTrace <b>(BETA)</b>
            </Typography>
            <TextField
                fullWidth
                value={username}
                margin="normal"
                required
                onChange={({target}) => setUsername(target.value)}
                label="Username"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                onKeyPress={(event) => {
                    if(!isEnterKey(event)) return;
                    login();
                }}
            />
            <TextField
                fullWidth
                value={password}
                margin="normal"
                required
                onChange={({target}) => setPassword(target.value)}
                type="password"
                label="Password"
                variant="outlined"
                autoComplete="current-password"
                InputLabelProps={{
                    shrink: true,
                }}
                onKeyPress={(event) => {
                    if(!isEnterKey(event)) return;
                    login();
                }}
            />
            <Button
                className={classes.submitButton}
                fullWidth
                variant="contained"
                color="primary"
                onClick={login}
            >
                Sign in
            </Button>
            <Typography
                align="center"
                component="h5"
                variant="h5"
                className={classes.signUpInfo}
            >
                Don't have an account? Sign up
                &nbsp;
                <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSf030BnX5ISXaXZeKZWQRbjnWvrFapvn2RFD_kZ0SC8tt3dJQ/viewform"
                    target="_blank"
                >
                    here!
                </a>
            </Typography>
        </>
    );
};

export default withRouter(observer(Login));



