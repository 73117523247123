export const norms =  [
    {
        id: 0,
        value: "A240/A240M-18",
        grades: [
            {
                id: 0,
                value: "S31254",
                elements: [
                    {
                        id: 1,
                        value: 0,
                        min: 0,
                        max: 0.020,
                        bm: "C"
                    },
                    {
                        id: 2,
                        value: 0,
                        min: 0,
                        max: 1.00,
                        bm: "Mn"
                    },
                    {
                        id: 3,
                        value: 0,
                        min: 0,
                        max: 0.030,
                        bm: "P"
                    },
                    {
                        id: 4,
                        value: 0,
                        min: 0,
                        max: 0.010,
                        bm: "S"
                    },
                    {
                        id: 5,
                        value: 0,
                        min: 0,
                        max: 0.80,
                        bm: "Si"
                    },
                    {
                        id: 6,
                        value: 0,
                        min: 19.5,
                        max: 20.5,
                        bm: "Cr"
                    },
                    {
                        id: 7,
                        value: 0,
                        min: 17.5,
                        max: 18.5,
                        bm: "Ni"
                    },
                    {
                        id: 8,
                        value: 0,
                        min: 6,
                        max: 6.5,
                        bm: "Mo"
                    },
                    {
                        id: 9,
                        value: 0,
                        min: 0.18,
                        max: 0.25,
                        bm: "N"
                    },
                    {
                        id: 10,
                        value: 0,
                        min: 0.50,
                        max: 1.00,
                        bm: "Cu"
                    },
                    {
                        id: 11,
                        value: 0,
                        min: 34,
                        formula: "Cr+3.3*Mo+N*16",
                        bm: "Pren"
                    },
                ]
            },
            {
                id: 1,
                value: "S31266",
                elements: [
                    {
                        id: 1,
                        value: 0,
                        min: 0,
                        max: 0.030,
                        bm: "C"
                    },
                    {
                        id: 2,
                        value: 0,
                        min: 0,
                        max: 1.00,
                        bm: "Mn"
                    },
                    {
                        id: 3,
                        value: 0,
                        min: 0,
                        max: 0.030,
                        bm: "P"
                    },
                    {
                        id: 4,
                        value: 0,
                        min: 0,
                        max: 0.010,
                        bm: "S"
                    },
                    {
                        id: 5,
                        value: 0,
                        min: 0,
                        max: 0.80,
                        bm: "Si"
                    },
                    {
                        id: 6,
                        value: 0,
                        min: 19.5,
                        max: 20.5,
                        bm: "Cr"
                    },
                    {
                        id: 7,
                        value: 0,
                        min: 17.5,
                        max: 18.5,
                        bm: "Ni"
                    },
                    {
                        id: 8,
                        value: 0,
                        min: 0.5,
                        max: 6.0,
                        bm: "Mo"
                    },
                    {
                        id: 9,
                        value: 0,
                        min: 0.18,
                        max: 0.25,
                        bm: "N"
                    },
                    {
                        id: 10,
                        value: 0,
                        min: 0.50,
                        max: 1.00,
                        bm: "Cu"
                    },
                    {
                        id: 11,
                        value: 0,
                        min: 34,
                        formula: "Cr+3.3*Mo+N*16",
                        bm: "Pren"
                    },
                ]
            },
            {
                id: 2,
                value: "S31803",
                elements: [
                    {
                        id: 1,
                        value: 0,
                        min: 0,
                        max: 0.030,
                        bm: "C"
                    },
                    {
                        id: 2,
                        value: 0,
                        min: 0,
                        max: 2.00,
                        bm: "Mn"
                    },
                    {
                        id: 3,
                        value: 0,
                        min: 0,
                        max: 0.030,
                        bm: "P"
                    },
                    {
                        id: 4,
                        value: 0,
                        min: 0,
                        max: 0.020,
                        bm: "S"
                    },
                    {
                        id: 5,
                        value: 0,
                        min: 0,
                        max: 1.00,
                        bm: "Si"
                    },
                    {
                        id: 6,
                        value: 0,
                        min: 21.0,
                        max: 23.0,
                        bm: "Cr"
                    },
                    {
                        id: 7,
                        value: 0,
                        min: 4.5,
                        max: 6.5,
                        bm: "Ni"
                    },
                    {
                        id: 8,
                        value: 0,
                        min: 2.5,
                        max: 3.5,
                        bm: "Mo"
                    },
                    {
                        id: 9,
                        value: 0,
                        min: 0.08,
                        max: 0.20,
                        bm: "N"
                    },
                    {
                        id: 10,
                        value: 0,
                        min: 0,
                        max: 0,
                        bm: "Cu"
                    },
                    {
                        id: 11,
                        value: 0,
                        min: 34,
                        formula: "Cr+3.3*Mo+N*16",
                        bm: "Pren"
                    },
                ]
            },
        ]
    },
    {
        id: 1,
        value: "API 5L, PSL2",
        grades: [
            {
                id: 0,
                value: "X60 Q",
                elements: [
                    {
                        id: 1,
                        value: 0,
                        min: 0.030,
                        max: 0.160,
                        bm: "C"
                    },
                    {
                        id: 2,
                        value: 0,
                        min: 0,
                        max: 0.45,
                        bm: "Si"
                    },
                    {
                        id: 3,
                        value: 0,
                        min: 0,
                        max: 1.7,
                        bm: "Mn"
                    },
                    {
                        id: 4,
                        value: 0,
                        min: 0,
                        max: 0.02,
                        bm: "P"
                    },
                    {
                        id: 5,
                        value: 0,
                        min: 0,
                        max: 0.0080,
                        bm: "S"
                    },
                    {
                        id: 6,
                        value: 0,
                        min: 0.01,
                        max: 0.05,
                        bm: "Al"
                    },
                    {
                        id: 7,
                        value: 0,
                        min: 0,
                        max: 0.5,
                        bm: "Cu"
                    },
                    {
                        id: 8,
                        value: 0,
                        min: 0,
                        max: 0.5,
                        bm: "Cr"
                    },
                    {
                        id: 9,
                        value: 0,
                        min: 0,
                        max: 0.5,
                        bm: "Ni"
                    },
                    {
                        id: 10,
                        value: 0,
                        min: 0,
                        max: 0.5,
                        bm: "Mo"
                    },
                    {
                        id: 11,
                        value: 0,
                        min: 0,
                        max: 0.06,
                        bm: "V"
                    },
                    {
                        id: 12,
                        value: 0,
                        min: 0,
                        max: 0.02,
                        bm: "As"
                    },
                    {
                        id: 13,
                        value: 0,
                        min: 0,
                        max: 0.015,
                        bm: "Sn"
                    },
                    {
                        id: 14,
                        value: 0,
                        min: 0,
                        max: 0.02,
                        bm: "Ti"
                    },
                    {
                        id: 15,
                        value: 0,
                        min: 0,
                        max: 0.05,
                        bm: "Nb_Cb"
                    },
                    {
                        id: 16,
                        value: 0,
                        min: 0,
                        max: 0.01,
                        bm: "N"
                    },
                    {
                        id: 17,
                        value: 0,
                        min: 0,
                        max: 0.01,
                        bm: "Pb"
                    },
                    {
                        id: 18,
                        value: 0,
                        min: 0,
                        max: 0.0005,
                        bm: "B"
                    },
                    {
                        id: 19,
                        value: 0,
                        min: 0.0006,
                        max: 0.005,
                        bm: "Ca"
                    },
                    {
                        id: 20,
                        value: 0,
                        min: 0,
                        max: 0.01,
                        bm: "Bi"
                    },
                    {
                        id: 21,
                        value: 0,
                        min: 0,
                        max: 0.21,
                        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
                        bm: "CQ_08"
                    },
                    {
                        id: 22,
                        value: 0,
                        min: 0,
                        max: 0.12,
                        formula: "V+Ti+Nb_Cb",
                        bm: "EF_02"
                    },
                    {
                        id: 23,
                        value: 0,
                        min: 34,
                        formula: "Cr+3.3*Mo+N*16",
                        bm: "Pren"
                    },
                ]
            }
        ]
    },
    {
        id: 2,
        value: "A333M",
        grades: [
            {
                id: 0,
                value: "Grade 6",
                elements: [
                    {
                        id: 1,
                        value: 0,
                        min: 0,
                        max: 0.30,
                        bm: "C"
                    },
                    {
                        id: 2,
                        value: 0,
                        min: 0.1,
                        max: 0,
                        bm: "Si"
                    },
                    {
                        id: 3,
                        value: 0,
                        min: 0.29,
                        max: 1.06,
                        bm: "Mn"
                    },
                    {
                        id: 4,
                        value: 0,
                        min: 0,
                        max: 0.025,
                        bm: "P"
                    },
                    {
                        id: 5,
                        value: 0,
                        min: 0,
                        max: 0.025,
                        bm: "S"
                    },
                    {
                        id: 7,
                        value: 0,
                        min: 0,
                        max: 0.4,
                        bm: "Cu"
                    },
                    {
                        id: 8,
                        value: 0,
                        min: 0,
                        max: 0.3,
                        bm: "Cr"
                    },
                    {
                        id: 9,
                        value: 0,
                        min: 0,
                        max: 0.4,
                        bm: "Ni"
                    },
                    {
                        id: 10,
                        value: 0,
                        min: 0,
                        max: 0.12,
                        bm: "Mo"
                    },
                    {
                        id: 11,
                        value: 0,
                        min: 0,
                        max: 0.08,
                        bm: "V"
                    },
                    {
                        id: 15,
                        value: 0,
                        min: 0,
                        max: 0.02,
                        bm: "Nb_Cb"
                    },

                    {
                        id: 23,
                        value: 0,
                        min: 0,
                        max: 0.41,
                        bm: "Pren",
                        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
                    },
                ]
            }
        ]
    },
    {
        id: 3,
        value: "A105/A105M-18",
        grades: [
            {
                id: 0,
                value: "K03504",
                elements: [
                    {
                        id: 1,
                        value: 0,
                        min: 0,
                        max: 0.35,
                        bm: "C"
                    },
                    {
                        id: 3,
                        value: 0,
                        min: 0.6,
                        max: 1.05,
                        bm: "Mn"
                    },
                    {
                        id: 4,
                        value: 0,
                        min: 0,
                        max: 0.35,
                        bm: "P"
                    },
                    {
                        id: 5,
                        value: 0,
                        min: 0,
                        max: 0.04,
                        bm: "S"
                    },
                    {
                        id: 2,
                        value: 0,
                        min: 0.1,
                        max: 0.35,
                        bm: "Si"
                    },
                    {
                        id: 7,
                        value: 0,
                        min: 0,
                        max: 0.4,
                        bm: "Cu"
                    },
                    {
                        id: 9,
                        value: 0,
                        min: 0,
                        max: 0.4,
                        bm: "Ni"
                    },
                    {
                        id: 8,
                        value: 0,
                        min: 0,
                        max: 0.3,
                        bm: "Cr"
                    },
                    {
                        id: 10,
                        value: 0,
                        min: 0,
                        max: 0.12,
                        bm: "Mo"
                    },
                    {
                        id: 11,
                        value: 0,
                        min: 0,
                        max: 0.08,
                        bm: "V"
                    },
                    {
                        id: 12,
                        value: 0,
                        min: 0,
                        max: 1,
                        bm: "A",
                        formula: "Cu+Ni+Cr+Mo+V",
                    },
                    {
                        id: 13,
                        value: 0,
                        min: 0,
                        max: 0.32,
                        bm: "B",
                        formula: "Cr+Mo",
                    },
                    {
                        id: 23,
                        value: 0,
                        min: 0,
                        max: 0.48,
                        getMax(wallThicknessInInches){
                            return wallThicknessInInches > 2 ? 0.47 : 0.48;
                        },
                        bm: "Pren",
                        label: "CE",
                        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
                    },
                ]
            }
        ]
    }
];