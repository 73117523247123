export default () => ({
    label: {
        marginBottom: 15,
        fontWeight: 600,
    },
    content: {
        fontSize: 16,
        padding: "15px 30px",
    },
    container: {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
    },
});