export default () => ({
  liquidPenetrant: {
    "& table": {
      marginBottom: 30,
      "& tbody": {
        borderColor: "#616161",
        "& td": {
          padding: "15px 25px",
          color: "#616161"
        }
      }
    }
  },
  item: {
    marginBottom: 20,
  },
  redValidate: {
    color: "#f44336",
    fontWeight: "600",
    paddingRight: "0",
    paddingTop: "40px",
    marginTop: "-60px",
    fontSize: "18px"
  },

  table: {
    "& td:first-of-type": {
      width: "80%"
    }
  },

  name: {
    fontWeight: 400,
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: 700,
  },

  margin: {
    margin: "15px 0"
  },

  padding: {
    paddingRight: "50px",
    paddingLeft: "50px"
  },

  positionBottom: {
    display: "flex",
    alignItems: "flex-end"
  },
  additional: {
    paddingLeft: 60,
    flexDirection: "column",
    display: "flex",
  },
  sketch: {
    marginTop: 150,
    "& img": {
      maxWidth: "100%",
    }
  }
});
