import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ImpactTestTable from "./ImpactTestTable";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ImpactTest = ({test}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.infoRow}>
        <Grid item xs={4}>
          <div>
            Product norm:
            <span className={classes.infoValue}>{test.properties.specification}</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            Grade:
            <span className={classes.infoValue}>{test.properties.grade}</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>Temperature:
            <span className={classes.infoValue}>{test.properties.temperature}</span>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.infoRow}>
        <Grid item xs={4}>
          <div>
            Test norm:
            <span className={classes.infoValue}>{test.properties.testNorm || "-"}</span>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div>
            Correlation standards:
            <span className={classes.infoValue}>{test.properties.correlationStandards || "-"}</span>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.infoRow}>
        <Grid item xs={4}>
          <div>
            Client:
            <span className={classes.infoValue}>{test.properties.client || "-"}</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            Dimensions:
            <span
              className={classes.infoValue}>{test.properties.width || "- "}mm x {test.properties.height || "- "}mm</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            Notch:
            <span className={classes.infoValue}>{test.properties.notch || "-"}</span>
          </div>
        </Grid>
      </Grid>

      <ImpactTestTable table={test.properties}/>
    </div>
  );
};

export default ImpactTest;

