export default () => ({
  marginBottom: {
    marginBottom: "32px"
  },

  values: {
    fontWeight: 700,
    color: "#616161"
  },

  name: {
    fontWeight: 400,
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: 700,
  },

  gridFields: {
    display: "flex",
    "& td, & th": {
      border: "1px solid rgb(224, 224, 224)",
      padding: "4px 8px !important",
      textAlign: "center",
    },
    "& table:not(:first-child) td, & table:not(:first-child) th": {
      borderLeft: "none",
    }
  },

  tableInput: {
    "& td, & th": {
      border: "1px solid rgb(224, 224, 224)",
      padding: "4px 8px !important",
      textAlign: "center",
    }
  },
  automaticTable: {
    marginTop: 30,
    "& td": {
      textAlign: "center",
      border: "1px solid #b7b7b7"
    },
    "& .tableTitles": {
      fontWeight: 400,
      paddingTop: "10px",
      color: "#616161",
      marginBottom: "5px",
      textAlign: "left"
    }
  },
  tableTitles: {
    fontWeight: 400,
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
    textAlign: "left"
  },
  summaryGreen: {
    fontWeight: 400,
    color: "#4caf50"
  },
  summaryRed: {
    fontWeight: 500,
    color: "rgb(208, 45, 82)",
  }
});

