import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {platform} from 'config';
import styles from "./styles";

const useStyles = makeStyles(styles);

const Marking = ({certificate}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={6}>
            {certificate.marking_image && (
                <Grid item xs={4} className={classes.markingImage}>
                    <img
                        src={platform + certificate.marking_image}
                        alt="marking image"
                    />
                </Grid>)}
            <Grid item xs={8}>
                {certificate.marking_text}
            </Grid>
        </Grid>
    )
};

export default Marking;