export default (theme) => ({
    header: {
        color: theme.palette.primary.main,
        padding: "10px 15px",
        display: "flex",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        "& h4": {
            display: "flex",
            alignItems: "center",
        }
    },
    actionsCell: {
        display: "flex",
        justifyContent: "space-around",
        "& button": {
            width: "50%",
        },
        "& a": {
            width: "50%",
        }
    },
    table: {
        "& th": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
        },
        "& td": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
        },
        "& td:last-child": {
            borderRight: "none",
        },
        "& th:last-child": {
            borderRight: "none",
        },
        "& tr:last-child": {
            "& td": {
                borderBottom: "none",
            }
        }
    },
    noFoundText: {
        color: "#0000008a",
    }
});