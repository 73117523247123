import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const ProtectedRoute = (props) => {
    const token = localStorage.getItem("accessToken");

    if (!token) return <Redirect to="/login"/>;
    return <Route>{props.children}</Route>;
};

export default ProtectedRoute;
