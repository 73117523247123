import React from "react";
import {Button, AppBar} from '@material-ui/core';
import {NavLink, useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/core"
import {TABS} from "@core/constants/orders";
import styles from "./styles";

const useStyles = makeStyles(styles);

const NavigationBar = () => {
    const location = useLocation();
    const classes = useStyles();

    const status = new URLSearchParams(location.search).get("status") || TABS.OPEN;

    return (
        <AppBar color="inherit" position="static" className={classes.menu}>
            <NavLink
                isActive={() => status === TABS.OPEN}
                to={`/orders?status=${TABS.OPEN}`}
                activeClassName={classes.active}
            >
                <Button>{TABS.OPEN}</Button>
            </NavLink>
            <NavLink
                isActive={() => status === TABS.CLOSED}
                to={`/orders?status=${TABS.CLOSED}`}
                activeClassName={classes.active}
            >
                <Button>{TABS.CLOSED}</Button>
            </NavLink>
        </AppBar>
    )
};

export default NavigationBar