import React from 'react';
import {Divider, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {isEmpty} from "ramda";
import Header from "./Header";
import ProductInfo from "./ProductInfo";
import Owner from "./Owner";
import PreviousOwner from './PreviousOwner'
import Summary from "./Summary";
import Notes from "./Notes";
import Items from "./Items";
import Marking from "./Marking";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Certificate = ({certificate, executeScroll, tests}) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Header certificate={certificate} />
            <Divider />
            <Grid container spacing={6} className={classes.content}>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6" className={classes.label}>
                        Product Info
                    </Typography>
                    <ProductInfo certificate={certificate} />
                </Grid>
                <Grid item xs={3}>
                    <Typography component="h6" variant="h6" className={classes.label}>
                        Owner
                    </Typography>
                    <Owner certificate={certificate} />
                </Grid>
                <Grid item xs={3}>
                    {certificate.previous_owners && !isEmpty(certificate.previous_owners) && (
                        <>
                            <Typography component="h6" variant="h6" className={classes.label}>
                                Previous Owner
                            </Typography>
                            <PreviousOwner certificate={certificate} />
                        </>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6" className={classes.label}>
                        Summary
                    </Typography>
                    <Summary tests={tests} executeScroll={executeScroll}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6" className={classes.label}>
                        Items
                    </Typography>
                    <Items certificate={certificate} />
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6" className={classes.label}>
                        Notes
                    </Typography>
                    <Notes certificate={certificate} />
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6" className={classes.label}>
                        Marking
                    </Typography>
                    <Marking certificate={certificate} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Certificate;
