import ReactDOMServer from "react-dom/server";
import React from "react";
import {create} from 'jss';
import {CssBaseline} from '@material-ui/core';
import {ServerStyleSheets, ThemeProvider, jssPreset} from "@material-ui/core/styles";
import jsonxml from "jsontoxml";
import {generatePdf} from "@core/api/pdfService";
import {compose, descend, flatten, groupBy, map, path, prop, sort, values} from "ramda";
import {TEST_ORDER} from "@core/constants/test";
import {host} from 'config';
import theme from '../../themeConfig';

const convertReactComponentToHTML = (component) => {
    const jss = create().setup({...jssPreset(), Renderer: null});

    const sheets = new ServerStyleSheets({jss});

    const html = ReactDOMServer.renderToStaticMarkup(
        sheets.collect(
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div style={{background: "#fafafa"}}>
                    {component}
                </div>
            </ThemeProvider>
        )
    );
    const css = sheets.toString();

    return `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charSet="utf-8" /> 
            <base href="${host}" />
            <style id="jss-server-side">${css}</style>
          </head>
          <body>
            <div id="root">${html}</div>
          </body>
        </html>
    `;
};

const download = (filename, href) => {
    const element = document.createElement('a');
    element.href = href;
    element.download = filename;
    element.click();
}

export const downloadXml = (filename, data) => {
    const options = {prettyPrint: true, escape: true, xmlHeader: true};
    const xml = jsonxml(data, options);
    download(filename, `data:text/plain;charset=utf-8,${encodeURIComponent(xml)}`);
}

export const downloadJSON = (filename, data) => {
    const json = JSON.stringify(data);
    download(filename, `data:text/plain;charset=utf-8,${encodeURIComponent(json)}`);
}

export const downloadPdf = async (filename, ReactComponent) => {
    const html = convertReactComponentToHTML(ReactComponent);
    const pdfContent = await generatePdf(html);
    const href = window.URL.createObjectURL(pdfContent);
    download(filename, href);
}

export const sortTestsByType = (tests) => {
    const sortedTests = sort(descend(prop("date_created")))(tests);
    const testsByCompanyId = groupBy(path(["company", "_id"]))(sortedTests);

    const sortByType = (companyTests) => sort((testA, testB) => {
        return TEST_ORDER.indexOf(testA.type) - TEST_ORDER.indexOf(testB.type);
    }, companyTests);

    return flatten(compose(values, map((companyTests) => sortByType(companyTests)))(testsByCompanyId));
};

export const getFileExtension = (fullName) => {
    return fullName.split(".")[1];
}

export const getFileName = (fullName) => {
    return fullName.split(".")[0];
}