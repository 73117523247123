import React, {useEffect, useState, useCallback} from 'react';
import {useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {useStores} from "@core/hooks/useStores";
import {isEmpty, values} from 'ramda';
import {Grid, TablePagination, Button} from '@material-ui/core';
import PageLoader from "@core/components/PageLoader";
import {TABS} from "@core/constants/orders";
import OrdersList from "./components/OrdersList";
import FiltrationPanel from "./components/FiltrationPanel";
import UploadFiles from './components/UploadFiles';
import Files from './components/Files';
import baseESQuery from "./baseESQuery";

const getColumns = (order) => [{
    dataIndex: "index",
    title: "LI",
    width: "5%",
    render: (_, index) => index + 1,
}, {
    dataIndex: "certificateType",
    title: "Certificate type",
    width: "10%",
}, {
    dataIndex: "productType",
    title: "Product type",
    width: "10%",
}, {
    dataIndex: "actions",
    title: "Actions",
    width: "15%",
    render: (lineItem, index) => (
        <Grid container justify="space-around">
            <Grid item>
                <UploadFiles
                    projectId={lineItem.projectId}
                    status={lineItem.status}
                    certificateId={lineItem.certificate}
                    certificateType={lineItem.certificateType}
                    productType={lineItem.productType}
                    lineItem={index}
                    order={order}
                />
            </Grid>
            <Grid item>
                <Button
                    fullWidth
                    color="primary"
                    href={`/orders/${order._id}/${index}`}
                >
                    View
                </Button>
            </Grid>
        </Grid>
    ),
}, {
    dataIndex: "files",
    title: "File(s)",
    width: "30%",
    align: "left",
    render: (_, index) => (
        <Files
            lineItem={index}
            orderNumber={order.orderNumber}
        />
    ),
}, {
    dataIndex: "description",
    width: "20%",
    title: "Description",
}, {
    dataIndex: "status",
    width: "10%",
    title: "Status",
}];

const getFormattedSearch = (value) => {
    const formattedValues = value.toLowerCase().split(" ");
    const base = {
        bool: {
            must: []
        }
    };

    formattedValues.forEach((value) => {
        if (value !== "") {
            base.bool.must.push(baseESQuery(value));
        }
    });

    return encodeURIComponent(JSON.stringify(base));
}

const Orders = () => {
    const [isSearching, setIsSearching] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    const location = useLocation();

    const {OrdersStore} = useStores();

    const loadOrders = async (search) => {
        setIsSearching(true);

        const status = new URLSearchParams(location.search).get("status") || TABS.OPEN;
        const params = {offset, limit, status};
        if(search) {
            params.search = getFormattedSearch(search);
        }
        await OrdersStore.getList(params);

        setIsSearching(false);
    };

    useEffect(() => {
        loadOrders(search);
    }, [offset, limit, location.search])

    const searchAPIDebounced = useCallback(AwesomeDebouncePromise(loadOrders, 500), []);

    useEffect(() => {
       searchAPIDebounced(search);
    }, [search])

    const {ordersById, total, isLoaded} = OrdersStore;
    const orders = values(ordersById);

    if(!isLoaded) return <PageLoader />;

    return (
        <>
            <FiltrationPanel
                isLoaded={isLoaded && isEmpty(orders)}
                isSearching={isSearching}
                setOffset={setOffset}
                setSearch={setSearch}
            />
            <OrdersList
                orders={orders}
                getColumns={getColumns}
            />
            <TablePagination
                labelRowsPerPage="Orders per page"
                component="div"
                count={total}
                page={offset / limit}
                onChangePage={(_, page) => {
                    setOffset(page * limit);
                }}
                rowsPerPage={limit}
                onChangeRowsPerPage={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    setOffset(0);
                }}
            />
        </>
    );
};

export default observer(Orders);