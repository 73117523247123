import React from 'react';
import {DropzoneAreaBase} from "material-ui-dropzone";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {useStores} from "@core/hooks/useStores";
import AttachIcon from "../AttachIcon";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ACCEPTED_MIME_TYPES = [
    'image/*',
    'application/pdf',
    'text/plain',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint',
    'application/ld+json',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/rtf',
];

const DropZone = ({orderNumber, lineItem, disabled}) => {
    const classes = useStyles();

    const {UploadsStore} = useStores();

    const onDelete = (deletedFileObject) => {
        UploadsStore.deleteFile(orderNumber, lineItem, deletedFileObject._id);
    }

    const onAdd = async (newFiles) => {
        for (const fileObject of newFiles) {
            await UploadsStore.uploadFile(orderNumber, lineItem, fileObject.file);
        }
    }

    const lineItemUploads = UploadsStore.lineItemUploads[`${orderNumber}.${lineItem}`] || [];

    return (
        <div className={disabled ? classes.hideRemoveButton : classes.fileDropzone}>
            <DropzoneAreaBase
                previewGridClasses={{
                    item: classes.item,
                }}
                dropzoneProps={{
                    disabled,
                }}
                maxFileSize={30000000}
                showAlerts={false}
                fileObjects={lineItemUploads}
                filesLimit={10}
                showFileNames
                getPreviewIcon={() => <AttachIcon/>}
                acceptedFiles={ACCEPTED_MIME_TYPES}
                onAdd={onAdd}
                onDelete={onDelete}
            />
        </div>
    )
};

export default observer(DropZone);
