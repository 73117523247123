import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, List} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Avatar from "@core/components/Avatar";
import classNames from 'classnames';
import {observer} from "mobx-react-lite";

import ListMenuItem from "../ListMenuItem";
import MenuItem from "../MenuItem";
import styles from "./styles";
import {useStores} from "@core/hooks/useStores";

const menu = [{
    title: "Dashboard",
    icon: <DashboardIcon/>,
    link: '/index',
}, {
    title: "Orders",
    icon: <LocalShippingIcon/>,
    link: '/orders',
}, {
    title: "Account",
    icon: <AccountBoxIcon/>,
    link: '/account',
}, {
    title: "Logout",
    icon: <ExitToAppIcon/>,
    link: '/logout',
}];

const useStyles = makeStyles(styles);

const Menu = ({open, toggleMenu}) => {
    const classes = useStyles();

    const {UsersStore} = useStores();

    const firstName = UsersStore.user.firstname || "";
    const lastName = UsersStore.user.lastname || "";

    return (
        <Drawer
            variant="permanent"
            className={classNames({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: classNames(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <List className={classes.menuList}>
                <ListMenuItem
                    active
                    icon={(
                        <Avatar
                            firstName={firstName}
                            lastName={lastName}
                            className={classes.avatar}
                        />
                    )}
                    itemName={`${firstName} ${lastName}`}
                    link="/account"
                />
                {menu.map(item => (
                    <MenuItem
                        open={open}
                        key={item.title}
                        item={item}
                    />
                ))}
                <ListMenuItem
                    className={classes.menuToggleButton}
                    onClick={toggleMenu}
                    icon={open ? <ArrowBackIcon/> : <ArrowForwardIcon/>}
                />
            </List>
        </Drawer>
    );
}

export default observer(Menu);