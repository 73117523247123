export default (theme) => ({
  radioGraphic: {
    width: "100%",
    "& h4": {
      fontWeight: 400,
      paddingTop: 10,
      color: "#616161",
    },
    "& p": {
      color: "#616161",
    },
    "& h2": {
      marginTop: 40,
      marginBottom: 30,
      fontWeight: 500,
      textTransform: "uppercase",
      fontFamily: "'Gotham Bold', sans-serif"
    }
  },
  source: {
    marginBottom: 10,
    "& h4": {
      color: "#616161",
    },
    "& span": {
      color: "#616161",
      fontWeight: 700,
    }
  },
  additional: {
    "&span": {
      display: "block",
      paddingBottom: 10,
    }
  },
  values: {
    fontWeight: 700,
    color: "#616161"
  },

  alignBottom: {
    marginLeft: "auto"
  },
  description: {
    wordBreak: "break-word",
    margin: "20px 0"
  },

  table: {
    "& table": {
      width: "auto",
      marginBottom: 75,
      overflowX: "hidden",
      tableLayout: "fixed",
      "& tbody": {
        borderColor: "#c9c9c9",
        "& tr": {
          maxWidth: "100%"
        },
        "& td": {
          border: "1px solid #C9C9C9",
          fontSize: 16,
          textAlign: "center",
          padding: "20px 10px",
          width: 80
        }
      }
    }
  },
  bold: {
    fontWeight: 500,
  },
  green: {
    color: theme.palette.success,
    "& a": {
      cursor: "pointer",
      fontWeight: 500,
    }
  }
});

