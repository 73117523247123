import React from "react";
import {Grid, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from "./styles";

const useStyles = makeStyles(styles);

const LiquidePenetrantTest = ({test}) => {
    const classes = useStyles();
    return <>
        <div className={classes.liquidPenetrant}>
            <Grid container spacing={2} direction="column">
                <Grid item xs={8}>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.name}>
                                    Cleaner
                                </TableCell>
                                <TableCell className={classes.value}>
                                    {test.properties.cleaner}
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Penetrant:</TableCell>
                                <TableCell className={classes.value}>{test.properties.penetrant}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Emulsifier:</TableCell>
                                <TableCell className={classes.value}>{test.properties.emulsifier || "NA"}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Solvent:</TableCell>
                                <TableCell className={classes.value}>{test.properties.solvent || "NA"}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Develepor:</TableCell>
                                <TableCell className={classes.value}>{test.properties.dev}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Trade name:</TableCell>
                                <TableCell className={classes.value}>{test.properties.tradeName}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Cleaner:</TableCell>
                                <TableCell className={classes.value}>{test.properties.cleanedBy}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Temperature:</TableCell>
                                <TableCell
                                    className={classes.value}>{`${test.properties.temperatureSurface} °C`}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Visible ambient light level:</TableCell>
                                <TableCell className={classes.value}>{`${test.properties.ambientLevel} Lx`}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Penetrant application by:</TableCell>
                                <TableCell className={classes.value}>{test.properties.penetrantApp}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Dwell time:</TableCell>
                                <TableCell className={classes.value}>{`${test.properties.dwelTime} Min.`}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Emulsifier application by:</TableCell>
                                <TableCell
                                    className={classes.value}>{test.properties.emulsifier_app || "NA"}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Penetrant removal by:</TableCell>
                                <TableCell className={classes.value}>{test.properties.removalBy}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Developer application:</TableCell>
                                <TableCell className={classes.value}>{test.properties.devApp}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Drying process:</TableCell>
                                <TableCell
                                    className={classes.value}>{test.properties.drying_process || "NA"}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Developing time:</TableCell>
                                <TableCell className={classes.value}>{`${test.properties.devTime} Min.`}</TableCell>
                            </TableRow>
                            <TableRow className={classes.item}>
                                <TableCell className={classes.name}>Time of interpretation:</TableCell>
                                <TableCell
                                    className={classes.value}>{`${test.properties.interpretationTime} Min.`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div className={classes.name}>Test norms(s): </div>
                    <span className={classes.value}> {test.properties.norm}</span>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.name}>Work stage: </div>
                    <span className={classes.value}>{test.properties.workStage}</span>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.name}>Surface condition: </div>
                    <span className={classes.value}>{test.properties.surfaceCondition}</span>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.name}>Acceptance criteria: </div>
                    <span className={classes.value}>{test.properties.acceptance}</span>
                </Grid>
            </Grid>
            <Grid item xs={6} className={classes.additional}>
                <div className={classNames(classes.item, classes.sketch)}>
                    <img src={test.properties.sketch.url}/>
                    <span className={classes.name}>
                        {test.properties.sketch.value}
                    </span>
                </div>
            </Grid>
        </div>
    </>;
}

export default LiquidePenetrantTest;
