import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, ClickAwayListener, Popover, Typography, Divider} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {useStores} from "@core/hooks/useStores";
import {isEmpty, head, drop} from "ramda";
import File from '@core/components/File';
import styles from "./styles";

const useStyles = makeStyles(styles);

const Files = ({orderNumber, lineItem}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);

    const {UploadsStore} = useStores();

    useEffect(() => {
        UploadsStore.getLineItemUploads(orderNumber, lineItem);
    }, [])


    const lineItemUploads = UploadsStore.lineItemUploads[`${orderNumber}.${lineItem}`] || [];
    const total = lineItemUploads.length;

    if (isEmpty(lineItemUploads)) return "No files uploaded";

    const first = head(lineItemUploads);
    const rest = drop(1, lineItemUploads);

    return (
        <div className={classes.container}>
            <File
                fileName={first.file.name}
                dir={first.file.dir}
            />
            {total > 1 && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Button
                        ref={anchorEl}
                        onClick={() => setOpen(true)}
                    >
                        {`+ ${total - 1}`}
                    </Button>
                </ClickAwayListener>
            )}
            <Popover
                classes={{
                    paper: classes.popover,
                }}
                open={open}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography className={classes.popoverHeader} component="h6" variant="h6" gutterBottom>Files</Typography>
                <Divider />
                {rest.map(fileObject => (
                    <div key={fileObject._id} className={classes.file}>
                        <File
                            fileName={fileObject.file.name}
                            dir={fileObject.file.dir}
                        />
                    </div>
                ))}
            </Popover>
        </div>
    );
};

export default observer(Files);
