import React, {useEffect} from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useStores} from "@core/hooks/useStores";
import LoginForm from './components/LoginForm';
import Statistics from "./components/Statistics";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Login = ({history}) => {
    const {StatisticsStore} = useStores();

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) history.push('/index');

        StatisticsStore.getStatistics();
    }, [])

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Grid container className={classes.content}>
                <Grid item xs={4} className={classes.loginSection}>
                    <LoginForm />
                </Grid>
                <Grid item xs={8} className={classes.statisticSection}>
                    <Statistics />
                </Grid>
            </Grid>
        </div>
    );
};

export default Login;



