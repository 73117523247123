import React from "react";
import {
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";

const TreatmentTest = ({test}) => {
    return (
        <Grid container alignContent="space-between">
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell size='small'>Treatment</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {test.properties.elements.map((element, index) => (
                            <TableRow key={`${test._id}.element.${index}`}>
                                <TableCell>{element.treatment}</TableCell>
                                <TableCell>{element.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

export default TreatmentTest;
