import React, {useState} from "react";
import {Grid, Table, TableBody, TableRow, TableCell, TableHead} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import {platform} from "config";
import ZoomInModal from "./zoomInModal";
import styles from "./styles";

const useStyles = makeStyles(styles);

const MicrographicTest = (props) => {
  const classes = useStyles();

  const [zoomOpened, setZoomOpened] = useState(false);
  const [modalElement, setModalElement] = useState({});

  const test = props.test.properties;
  const zoomIn = (element) => {
    setModalElement({...test, element});
    setZoomOpened(true);
  };
  const BM = test.samplingPositions.filter((sp) => sp.location === "BM");
  const WM = test.samplingPositions.filter((sp) => sp.location === "WM");
  const HAZ = test.samplingPositions.filter((sp) => sp.location === "HAZ");
  const tableElements = [{locations: BM, title: "BM"}, {locations: WM, title: "WM"}, {locations: HAZ, title: "HAZ"}];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} className={classes.section}>
          <span>Client</span>
          <div><b>{test.client}</b></div>
        </Grid>
        <Grid item xs={3} className={classes.section}>
          <span>Lab</span>
          <div><b>{test.lab}</b></div>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={3} className={classes.section}>
          <span>Test norms</span>
          <div><b>{test.norm}</b></div>
        </Grid>
        <Grid item xs={3} className={classes.section}>
          <span>Acceptance criteria</span>
          <div><b>{test.acceptanceCriteria}</b></div>
        </Grid>
        <Grid item xs={2} className={classes.section}>
          <span>Magnification</span>
          <div><b>{test.magnification}</b></div>
        </Grid>
        <Grid item xs={4} className={classes.section}>
          <span>Etching</span>
          <div><b>{test.etching || " - "}</b></div>
        </Grid>
        <Grid item xs={12} className={classes.section}>
          <span>Result notes</span>
          <div><b>{test.resultNotes}</b></div>
        </Grid>
        <Grid container spacing={2} className={classes.imagesGrid}>
          <Grid item xs={12}>
            {test.samplingPositions && <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{test.position1 ?? "External Surface"}</TableCell>
                  <TableCell>{test.position2 ?? "Mid thickness"}</TableCell>
                  <TableCell>{test.position3 ?? "Internal surface"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableElements.map((EL, index) => {
                  return (
                    <TableRow key={`${test._id}.element.${index}`}>
                      <TableCell>{EL.title}</TableCell>
                      {EL.locations.map((element, index) =>
                        <TableCell key={`${element.location}${index}`}>
                          <div className={classes.imageContainer}>
                            {EL.locations[index].image.preview_path && (
                              <>
                                <img src={`${platform}${EL.locations[index].image.preview_path}`} alt='micrographic photo' className={classes.img}/>
                                <div className={classes.imgOverlay} onClick={() => zoomIn(element)}>
                                  <ZoomInIcon size={40} className={classes.zoomIn} color={"#989898"}/>
                                </div>
                              </>
                            )}

                          </div>
                        </TableCell>)
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>}
            {!test.samplingPositions && test.image && <Grid item xs={6}>
              <img src={`${platform}${test.image.preview_path}`} alt='micrographic photo' className={classes.bigImg}/>
            </Grid>}
          </Grid>
        </Grid>
        {/*{*/}
        {/*  zoomOpened && <ZoomInModal*/}
        {/*    open={zoomOpened}*/}
        {/*    onClose={() => setZoomOpened(false)}*/}
        {/*    data={modalElement}*/}
        {/*  />*/}
        {/*}*/}
      </Grid>
    </>
  );
};

export default MicrographicTest;
