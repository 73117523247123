export default (value) => ({
  bool: {
    should: [
      {
        wildcard: {
          orderNumber: {
            value: `*${value}*`
          },
        }
      },
      {
        wildcard: {
          "lineItems.productType": {
            value: `*${value}*`
          },
        }
      },
    ]
  },
});
