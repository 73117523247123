import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from './styles';

const useStyles = makeStyles(styles);

const Header = ({certificate}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={6} className={classes.container}>
            <Grid item xs container>
                <Typography component="h6" variant="h6">
                    Heat:&nbsp;<b>{certificate.heat}</b>
                </Typography>
            </Grid>
            <Grid item xs container justify="flex-end">
                <Typography component="h6" variant="h6">
                    <div className={classes.qrContainer}>
                        <img src="/images/qr_code.svg"/>
                        Certificate:&nbsp;<b>{certificate._id}</b>
                    </div>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Header;