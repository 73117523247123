import React from 'react';
import ExpandableTimeline from './ExpandableTimeline';
import {makeStyles} from "@material-ui/core/styles";
import PlainTimeline from './PlainTimeline';
import styles from './styles';

const useStyles = makeStyles(styles);

const Timeline = ({expandable, transactions}) => {
    const classes = useStyles();

    const successTransactions = transactions.filter((transaction) => transaction.status !== "DECLINED");

    if (!successTransactions.length) {
        return (
            <div className={classes.unsigned}>
                Unsigned
            </div>
        );
    }

    if(expandable) {
        return (
            <ExpandableTimeline transactions={transactions} />
        )
    }
    return <PlainTimeline transactions={transactions} />
}

Timeline.defaultProps = {
    expandable: true,
};

export default Timeline;