export default () => ({
    somethingWentWrongImage: {
        height: "calc(100vh - 60px)",
    },
    container: {
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
    }
});