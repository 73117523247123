import {makeObservable, observable, action} from "mobx"
import axios from "axios";
import {indexBy, prop} from "ramda";
import {api} from "config";

class OrdersStore {
    isLoaded = false;
    ordersById = {};
    total = 0;

    constructor() {
        makeObservable(this, {
            isLoaded: observable,
            ordersById: observable,
            getList: action,
            getOrder: action,
        })
    }

    async getList(params) {
        const response = await axios({
            method: "get",
            url: `${api}/orders/list`,
            params,
        });

        this.ordersById = indexBy(prop('_id'), response.orders);
        this.total = response.total;
        this.isLoaded = true;
    }

    updateLineItem(orderId, lineItem, changes) {
        const lineItems = this.ordersById[orderId].lineItems.map((li, index) => index === lineItem ? {...li, ...changes} : li);

        this.ordersById[orderId] = {
            ...this.ordersById[orderId],
            lineItems,
        };
    }

    async getOrder(orderId) {
        this.isLoaded = false;

        const order = await axios({
            method: "get",
            url: `${api}/orders/single`,
            params: {id: orderId}
        });

        this.ordersById[orderId] = order;

        this.isLoaded = true;
    }
}

export default new OrdersStore();