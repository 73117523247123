import React from 'react';
import moment from 'moment';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@core/components/Avatar";
import {blockScout, platform} from 'config';
import {ACTION_LABELS} from "@core/constants/actions";
import styles from './styles';

const useStyles = makeStyles(styles);

const PlainTimeline = ({transactions}) => {
    const classes = useStyles();

    return (
        <>
            {transactions.map((transaction) => (
                <div key={transaction._id}>
                    <div className={classes.action}>{ACTION_LABELS[transaction.action]}</div>
                    <div className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Avatar
                                    className={classes.avatar}
                                    firstName={transaction.signer.firstname}
                                    lastName={transaction.signer.lastname}
                                    src={transaction.signer.avatar ?
                                        `${platform}${transaction.signer.avatar.file.dir}${transaction.signer.avatar.file.name}` :
                                        null
                                    }
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <div>
                                    {transaction.signer && `${transaction.signer.firstname} ${transaction.signer.lastname}`}
                                </div>
                                <div className={classes.signature}>
                                    <a target="_blank" href={`${blockScout}/tx/${transaction.signature}`}>
                                        {transaction.signature}
                                    </a>
                                </div>
                                <div className={classes.date}>
                                    {moment(transaction.date_created).format("MMM Do YYYY")}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {transaction.signer.company && (
                        <div className={classes.companyLogo}>
                            {transaction.signer.company.logo && (
                                <img
                                    src={platform + transaction.signer.company.logo}
                                    alt="company logo"
                                />
                            )}
                            {transaction.signer.company.name}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default PlainTimeline;
