import React from 'react';
import moment from "moment";
import {makeStyles} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Message = ({message}) => {
    const classes = useStyles();
    return (
        <>
            <Typography
                component="span"
                variant="body2"
                color="textPrimary"
                gutterBottom
            >
                <b>{message.username}</b>
                &nbsp;
                <span className={classes.date}>
                {moment(message.date).format("MMM Do YYYY, hh:mm:ss A")}
            </span>
            </Typography>
            <div dangerouslySetInnerHTML={{__html: message.message}}/>
        </>
    );
}

export default Message;